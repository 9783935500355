import {
	takeLatest, put, select, call,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { Location } from 'react-router-dom';
import {
	GET_CURRENT_USER,
	SIGN_IN,
	SIGN_IN_SUCCESS,
	SIGN_UP,
	SIGN_UP_SUCCESS,
	CONFIRM_EMAIL,
	CONFIRM_EMAIL_SUCCESS,
	TOKEN_REFRESH,
	REQUEST_RESET_PASSWORD,
	RESET_PASSWORD,
	GET_CURRENT_USER_SUCCESS,
	GET_CURRENT_USER_ERROR,
	GET_INVITE_DETAILS,
	GET_INVITE_DETAILS_SUCCESS,
	SIGN_OUT,
	TOKEN_REFRESH_ERROR,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	VERIFY_TOKEN,
	GET_CURRENT_SUBSCRIPTION_SUCCESS,
	GET_CURRENT_SUBSCRIPTION,
	GET_CURRENT_SUBSCRIPTION_ERROR,
	SIGN_IN_ERROR,
	SIGN_UP_ERROR,
} from '../constants';
import { buildRequestGenerator, fetchData, showError } from '../../api';
import { popupAlertShow } from '../actions';
import { selectUserSystemParams, selectWholeUserState } from '../selectors';
import { doGetFileUrl } from './fileUpload';
import { IApiSchema, TNavigate } from '../../types';
import { globalHandleError } from '../../utils/globalHandleError';
import i18n from '../../i18n';

const cookies = new Cookies();

function* doUserSignUp(
	{ payload, navigate }: { navigate: TNavigate, payload: IApiSchema['FormSignUp'] | IApiSchema['FormConfirmInvite'] },
) {
	const isInvite = 'code' in payload && payload.code;
	const lang = cookies.get('lng');

	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: isInvite ? 'inviteSignup' : 'signUp',
			requestBody: {
				...payload,
				lang,
				timezone_region: Intl.DateTimeFormat().resolvedOptions().timeZone,
			} as IApiSchema['FormSignUp'] | IApiSchema['FormConfirmInvite'],
		});

		const response: IApiSchema['CurrentUser'] = yield fetchData(request);

		yield showError(response);

		if (isInvite) {
			navigate('/sign-in');
		} else {
			navigate('/confirm-email');
		}

		yield put({ type: SIGN_UP_SUCCESS, email: response.email });
	} catch (error) {
		yield put({ type: SIGN_UP_ERROR });

		globalHandleError({
			module: 'auth',
			subModule: 'doUserSignUp',
			error,
		});
	}
}

function doUserLogout() {
	if (cookies) {
		cookies.remove('tokenValue', { path: '/' });
		cookies.remove('tokenExpires', { path: '/' });
	}
}

function* doGetCurrentCompany() {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'currentCompany',
		});
		const response: IApiSchema['CurrentCompany'] = yield fetchData(request);

		yield showError(response);

		yield put({ type: GET_CURRENT_SUBSCRIPTION_SUCCESS, payload: response });
	} catch (error) {
		yield put({ type: GET_CURRENT_SUBSCRIPTION_ERROR });
		globalHandleError({
			module: 'auth',
			subModule: 'doGetCurrentCompany',
			error,
		});
	}
}

function* doGetCurrentUser({
	navigate,
	location,
}: {
	navigate: TNavigate,
	location?: Location,
	changeLanguage?: (lng: string) => void
}) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'currentUser',
		});
		const response: IApiSchema['CurrentUser'] = yield fetchData(request);
		yield showError(response);

		const responseItem: unknown = yield call(
			// @ts-ignore
			doGetFileUrl,
			response,
			'member_profile_picture',
			response.member_profile_picture,
		);

		yield put({ type: GET_CURRENT_USER_SUCCESS, payload: responseItem });
		if (response.lang) {
			cookies.set('lng', response.lang, { path: '/' });
			i18n.changeLanguage(response.lang);
		}

		if (response?.email_verified) {
			yield doGetCurrentCompany();
		}

		if (response?.email_verified && !location) {
			navigate('/');
		} else if ('email_verified' in response && !response.email_verified) {
			navigate('/confirm-email');
		}

		return response?.email_verified;
	} catch (error) {
		yield put({ type: GET_CURRENT_USER_ERROR });
		const { isAuthenticated, inProgress } = yield select(selectUserSystemParams);

		if (!isAuthenticated && !inProgress) {
			navigate('/sign-in');
		}

		globalHandleError({
			module: 'auth',
			subModule: 'doGetCurrentUser',
			error,
		});
	}
}

function* doUserSignIn({ payload, navigate }: { payload: IApiSchema['FormSignIn'], navigate: TNavigate }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'signIn',
			requestBody: payload,
		});

		// todo: need backend types
		const response: {
			access_token?: string,
			expires_in?: number,
			refresh_token?: string,
			detail?: string,
		} = yield fetchData(request);

		yield showError(response);

		if (response.access_token) {
			yield put({ type: SIGN_IN_SUCCESS, token: response.access_token });
		}
		if (response.expires_in) {
			cookies.set('tokenExpires', Date.now() + response.expires_in * 1000, { path: '/' });
		}

		if (response.refresh_token) {
			cookies.set('tokenValue', response.refresh_token, { path: '/', httpOnly: false });
		}
		if (!response.detail) {
			yield doGetCurrentUser({ navigate });
		}
	} catch (error) {
		yield put({ type: SIGN_IN_ERROR });

		globalHandleError({
			module: 'auth',
			subModule: 'doUserSignIn',
			error,
		});
	}
}

function* doUserRequestResetPassword({ payload, navigate }: { payload: IApiSchema['FormEmail'], navigate: TNavigate }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'requestResetPassword',
			requestBody: payload,
		});
		const response: unknown = yield fetchData(request);

		yield showError(response);

		if (navigate) {
			navigate('/password-request-sent');
		} else {
			yield put(
				popupAlertShow({
					contentKey: 'passRecoverySentCustomers',
					contentParams: { email: payload.email },
					type: 'success',
					withCloseButton: true,
					iconName: 'mailSend',
				}),
			);
		}
	} catch (error) {
		globalHandleError({
			module: 'auth',
			subModule: 'doUserRequestResetPassword',
			error,
		});
	}
}

function* doUserResetPassword({ payload, isUpdate, navigate }: { payload: IApiSchema['FormUpdatePassword'] | IApiSchema['FormResetPassword'], navigate: TNavigate, isUpdate: boolean }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: isUpdate ? 'updatePassword' : 'resetPassword',
			requestBody: payload,
		});
		const response: unknown = yield fetchData(request);

		if (!isUpdate) {
			yield showError(response);
			navigate('/sign-in');
		}

		if (isUpdate) {
			if (response === true) {
				yield put(
					popupAlertShow({
						contentKey: 'dataSuccessfullySaved',
						type: 'success',
						withCloseButton: true,
						iconName: 'statusActive',
					}),
				);

				yield put({ type: RESET_PASSWORD_SUCCESS });
			} else {
				yield put(
					popupAlertShow({
						contentKey: 'wrongPass',
						type: 'error',
						timeout: 10000,
						withCloseButton: true,
						iconName: 'statusDeleted',
					}),
				);
				yield put({ type: RESET_PASSWORD_ERROR });
			}
		}
	} catch (error) {
		globalHandleError({
			module: 'auth',
			subModule: 'doUserResetPassword',
			error,
		});
	}
}

function* doUserConfirm({ payload }: { payload: IApiSchema['FormConfirmEmail'] }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'emailConfirm',
			requestBody: payload,
		});
		const response: {
			email_verified: boolean,
			status: string,
		} = yield fetchData(request);

		yield showError(response);

		if (response.email_verified) {
			yield put({ type: CONFIRM_EMAIL_SUCCESS, status: response.status });
			yield put(
				popupAlertShow({
					contentKey: 'emailConfirmed',
					type: 'success',
					withCloseButton: true,
					iconName: 'doggySign',
				}),
			);
		}
	} catch (error) {
		globalHandleError({
			module: 'auth',
			subModule: 'doUserConfirm',
			error,
		});
	}
}

function* doGetInviteDetails({ payload: { code } }: { payload: IApiSchema['FormConfirmInviteCode'] }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'inviteDetails',
			requestBody: { code },
		});
		const response: IApiSchema['CurrentUser'] = yield fetchData(request);

		// @ts-ignore
		if (response?.detail && Object.keys(response).length === 1) {
			yield showError({
				detail: 'invitationError',
			});
		}

		yield put({
			type: GET_INVITE_DETAILS_SUCCESS,
			payload: {
				email: response.email,
				company_name: response.customer_alias,
			},
		});
	} catch (error) {
		globalHandleError({
			module: 'auth',
			subModule: 'doGetInviteDetails',
			error,
		});
	}
}

export function* doRefreshToken() {
	try {
		const refreshTokenFromCookies: string = cookies.get('tokenValue');

		if (!refreshTokenFromCookies) {
			return;
		}

		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'refreshToken',
			requestBody: { refresh_token: refreshTokenFromCookies } as IApiSchema['FormRefreshToken'],
		});

		const response: {
			access_token?: string,
			expires_in?: number,
			refresh_token?: string,
			detail?: string,
		} = yield fetchData(request);

		yield showError(response);

		if (response.access_token) {
			yield put({ type: SIGN_IN_SUCCESS, token: response.access_token });
		}
		if (response.expires_in) {
			cookies.set('tokenExpires', Date.now() + response.expires_in * 1000, { path: '/' });
		}

		if (response.refresh_token) {
			cookies.set('tokenValue', response.refresh_token, { path: '/', httpOnly: false });
		}

		return response.access_token;
	} catch (error) {
		yield put({ type: TOKEN_REFRESH_ERROR });

		globalHandleError({
			module: 'auth',
			subModule: 'doRefreshToken',
			error,
		});
	}

	return '';
}

export function* verifyToken(tokenArg?: string, exp?: string) {
	const cookies = new Cookies();
	// verify validity of the token
	const { token: access_token, details } = yield select(selectWholeUserState);

	const expFromCookies = cookies.get('tokenExpires');

	const expirationDate = exp || details?.exp || expFromCookies;
	const tokenVerified = tokenArg || access_token;

	const isTokenExpired = !tokenVerified
		|| !expirationDate
		|| (expirationDate && Date.now() > +expirationDate);

	if (isTokenExpired) {
		return (yield doRefreshToken()) as string;
	}
	return tokenVerified;
}

export default function* authSaga() {
	return [
		// @ts-ignore
		yield takeLatest(SIGN_IN, doUserSignIn),
		// @ts-ignore
		yield takeLatest(SIGN_UP, doUserSignUp),
		// @ts-ignore
		yield takeLatest(SIGN_OUT, doUserLogout),
		// @ts-ignore
		yield takeLatest(GET_CURRENT_USER, doGetCurrentUser),
		// @ts-ignore
		yield takeLatest(GET_CURRENT_SUBSCRIPTION, doGetCurrentCompany),
		// @ts-ignore
		yield takeLatest(CONFIRM_EMAIL, doUserConfirm),
		// @ts-ignore
		yield takeLatest(REQUEST_RESET_PASSWORD, doUserRequestResetPassword),
		// @ts-ignore
		yield takeLatest(RESET_PASSWORD, doUserResetPassword),
		// @ts-ignore
		yield takeLatest(GET_INVITE_DETAILS, doGetInviteDetails),
		// @ts-ignore
		yield takeLatest(VERIFY_TOKEN, () => verifyToken()),
		// @ts-ignore
		yield takeLatest(TOKEN_REFRESH, doRefreshToken),
	];
}