export interface IApiSchema {
	Body_upload_file_by_contact_contact_upload_post: {
		file_obj: string;
	};
	Body_upload_file_by_operator_operator_upload_post: {
		file_obj: string;
	};
	ContactOnlineReportSchema: {
		customer_id: number;
		contact_id: number;
		sockets_count: number;
	};
	DepartmentInfo: {
		id: number;
		name: string;
	};
	IS3File: {
		file_name: string;
		file_size: number;
		file_code: string;
	};
	IS3FileUrl: {
		url: string;
	};
	OperatorsOnlineReportSchema: {
		customer_id: number;
		operator_id: number;
		sockets_count: number;
	};
	WidgetLiveChat: {
		name: string;
		license_id?: string;
		status?: IApiSchema['WidgetStatus'];
		install_status?: IApiSchema['WidgetInstallStatus'];
		channels?: IApiSchema['IChannelsStatuses'];
		settings?: IApiSchema['IWidgetSettings'];
		auto_reply_settings?: IApiSchema['IWidgetAutoReplaySettings'];
		departments?: IApiSchema['DepartmentInfo'][];
		timezone_offset?: string;
		timezone_region?: string;
		work_hours?: IApiSchema['ICustomerWorkHours'];
	};
	CountryCode: {
		code: string;
		dial_code: string;
		flag: string;
		name: Record<string, never>;
	};
	FormConfirmEmail: {
		code: string;
	};
	FormConfirmInvite: {
		code: string;
		full_name: string;
		password: string;
		lang?: IApiSchema['Language'];
	};
	FormConfirmInviteCode: {
		code: string;
	};
	FormRefreshToken: {
		refresh_token: string;
	};
	FormResetPassword: {
		code: string;
		password: string;
	};
	FormSignIn: {
		email: string;
		password: string;
	};
	FormSignUp: {
		email: string;
		password: string;
		full_name?: string;
		company_name?: string;
		company_phone?: string;
		lang?: IApiSchema['Language'];
		timezone_region?: string;
	};
	FormUpdatePassword: {
		email: string;
		password: string;
		new_password: string;
	};
	LimitOffsetPage_CountryCode_: {
		items: IApiSchema['CountryCode'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	SubscriptionLimits: {
		is_branding?: boolean;
		is_customization?: boolean;
		is_support?: boolean;
		templates?: number;
		users?: number;
		widgets?: number;
	};
	AdminPlanBrief: {
		id: number;
		customers: IApiSchema['CustomerShort'][];
		titles: IApiSchema['PlanTitle'];
		features: IApiSchema['PlanFeatures'];
		prices: IApiSchema['PlanPrice'];
		status: IApiSchema['PlanStatus'];
		is_custom: boolean;
		created_at: string;
	};
	AdminPlanDetailed: {
		id: number;
		customers: IApiSchema['CustomerShort'][];
		titles: IApiSchema['PlanTitle'];
		features: IApiSchema['PlanFeatures'];
		prices: IApiSchema['PlanPrice'];
		status: IApiSchema['PlanStatus'];
		is_custom: boolean;
		created_at: string;
	};
	ArticleCreateRequestSchema: {
		title: string;
		context: string;
		folder_id?: number;
		widget_license_id_list: string[];
		status: IApiSchema['KnowledgeBaseInstanceStatusEnum'];
		article_interactions: boolean;
	};
	ArticleCreateResponseSchema: {
		id: number;
		title: string;
		context: string;
		folder_id?: number;
		widget_license_id_list: string[];
		status: string;
		article_interactions: boolean;
		created_by: number;
		last_updated: string;
	};
	ArticleSchema: {
		id: number;
		title: string;
	};
	ArticlesSchemaResponse: {
		id: string;
		title: string;
		widget_license_id_list: string[];
		status: 'active' | 'disabled' | 'deleted',
		article_interactions: {
			likes: number;
			dislikes: number;
			views: number
		};
		last_updated: string;
	};
	ArticleSchemaWidgetDetailed: {
		id: number;
		title: string;
		context: string;
	};
	BuildPlan: {
		titles: IApiSchema['PlanTitle'];
		features: IApiSchema['PlanFeatures'];
		prices: IApiSchema['PlanPrice'];
		status: IApiSchema['PlanStatus'];
		is_custom?: boolean;
		customer_ids?: number[];
	};
	BulkBrief: {
		id: number;
		name: string;
		tags: string[];
		channels: IApiSchema['ChatChannelType'][];
		is_scheduled: boolean;
		scheduled_at: string;
		status: IApiSchema['BulkStatus'];
	};
	BulkDetailed: {
		id: number;
		name: string;
		tags: string[];
		channels: IApiSchema['ChatChannelType'][];
		is_scheduled: boolean;
		scheduled_at: string;
		status: IApiSchema['BulkStatus'];
		customer_id: number;
		message: string;
		files: Record<string, never>[];
		result?: Record<string, never>;
	};
	BulkStatus: 'draft' | 'scheduled' | 'in_progress' | 'complete' | 'complete_with_error';
	ChannelStatus: 'active' | 'inactive';
	ChatBrief: {
		id: number;
		contact_id: number;
		contact_uid: string;
		contact_name?: string;
		channel: IApiSchema['ChatChannelType'];
		widget_name?: string;
		chat_data?: Record<string, never>;
		last_message?: Record<string, never>;
		last_message_at?: string;
		status: IApiSchema['ChatStatus'];
		status_changed_at: string;
		data?: string;
	};
	ChatChannelType: 'live_chat' | 'callback' | 'telegram' | 'viber' | 'instagram' | 'facebook' | 'whatsapp';
	ChatEvent: 'contact_message' | 'operator_message' | 'system_message' | 'bulk_message' | 'callback_message' | 'operator_joined' | 'chat_started' | 'chat_closed' | 'forwarded_operator' | 'forwarded_department' | 'operator_rated' | 'contact_typing' | 'contact_not_typing' | 'operator_typing' | 'operator_not_typing';
	ChatMetric: 'chat_duration' | 'rating' | 'response_time';
	ChatStatus: 'new' | 'open' | 'closed' | 'forwarded';
	ContactBrief: {
		id: number;
		contact_uid: string;
		full_name?: string;
		email?: string;
		tags?: string[];
		widget_name?: string;
		channel: IApiSchema['ChatChannelType'];
		last_message_at?: string;
		chat_id?: number;
		total_messages?: number;
	};
	ContactDetailed: {
		id: number;
		contact_uid: string;
		full_name?: string;
		email?: string;
		tags?: string[];
		widget_name?: string;
		channel: IApiSchema['ChatChannelType'];
		last_message_at?: string;
		chat_id?: number;
		total_messages?: number;
		phone?: IApiSchema['Phone'];
		languages?: IApiSchema['Language'][];
		messengers?: IApiSchema['ChatChannelType'][];
		comment?: string;
		origin?: string;
		browser?: string;
		browser_lang?: string;
		device?: string;
		device_os?: string;
		first_message_at?: string;
		timezone?: string;
	};
	ContactMessaging: {
		id: number;
		contact_uid: string;
		full_name?: string;
		widget_id: number;
		widget_license_id: string;
		channel: IApiSchema['ChatChannelType'];
		chat_id: number;
		tags: unknown[];
	};
	CreateBulk: {
		name: string;
		message: string;
		files?: Record<string, never>[];
		at?: string;
		channels?: IApiSchema['ChatChannelType'][];
		tags?: string[];
		is_scheduled?: boolean;
		scheduled_at?: string;
		status?: IApiSchema['BulkStatus'];
		result?: Record<string, never>;
	};
	CreateDepartment: {
		name: string;
		member_ids: number[];
		status?: IApiSchema['DepartmentStatus'];
	};
	CreateTemplate: {
		name: string;
		body: string;
		operator_id?: number;
		status?: IApiSchema['TemplateStatus'];
	};
	CreateWidget: {
		name: string;
		license_id?: string;
		status?: IApiSchema['WidgetStatus'];
		install_status?: IApiSchema['WidgetInstallStatus'];
		channels?: IApiSchema['IChannelsStatuses'];
	};
	Currency: 'UAH' | 'USD' | 'EUR';
	CurrentCompany: {
		id: number;
		name: string;
		status: string;
		created_at: string;
		trial_available: boolean;
		owner_id?: number;
		subscription_id?: number;
		payment_type?: string;
		subscription_start_at?: string;
		subscription_end_at?: string;
		wfp_subscription_end_at?: string;
		counts?: IApiSchema['CurrentCounts'];
		limits?: IApiSchema['app__models__plan_subscription_model__SubscriptionLimits'];
		plan_id?: number;
		plan_titles?: IApiSchema['PlanTitle'];
		plan_prices?: IApiSchema['PlanPrice'];
		notifications?: IApiSchema['CustomerNotifications'];
		emails?: Record<string, never>;
		work_hours?: Record<string, never>;
		timezone_offset?: string;
		timezone_region?: string;
		at?: string;
	};
	CurrentCounts: {
		channels?: number;
		templates?: number;
		users?: number;
		widgets?: number;
	};
	CurrentUser: {
		id: number;
		auth_id: string;
		email: string;
		email_verified: boolean;
		role: IApiSchema['MemberRole'];
		lang?: IApiSchema['Language'];
		member_id?: number;
		member_full_name?: string;
		member_profile_picture?: string;
		member_status?: IApiSchema['MemberStatus'];
		phone?: string;
		languages?: IApiSchema['Language'][];
		customer_id?: number;
		customer_name?: string;
		customer_alias?: string;
		trial_available?: boolean;
		subscription_id?: number;
		plan_id?: number;
		plan_titles?: IApiSchema['PlanTitle'];
		limits?: IApiSchema['app__cache__schemas__current_user_schema__SubscriptionLimits'];
		at?: string;
	};
	CustomerBrief: {
		id: number;
		company_name: string;
		alias?: string;
		customer_tags?: string[];
		created_at: string;
		plan_id?: number;
		plan_titles?: IApiSchema['PlanTitle'];
		plan_prices?: IApiSchema['PlanPrice'];
		limits?: IApiSchema['app__models__plan_subscription_model__SubscriptionLimits'];
		subscription_id?: number;
		subscription_expire_at?: string;
		payment_type?: IApiSchema['PaymentType'];
		total_members_count?: number;
		trial_available: boolean;
		status: IApiSchema['CustomerStatus'];
		notifications?: IApiSchema['CustomerNotifications'];
	};
	CustomerDetailed: {
		id: number;
		company_name: string;
		alias?: string;
		customer_tags?: string[];
		created_at: string;
		plan_id?: number;
		plan_titles?: IApiSchema['PlanTitle'];
		plan_prices?: IApiSchema['PlanPrice'];
		limits?: IApiSchema['app__models__plan_subscription_model__SubscriptionLimits'];
		subscription_id?: number;
		subscription_expire_at?: string;
		payment_type?: IApiSchema['PaymentType'];
		total_members_count?: number;
		trial_available: boolean;
		status: IApiSchema['CustomerStatus'];
		notifications?: IApiSchema['CustomerNotifications'];
		company_logo?: string;
		industry?: IApiSchema['CustomerIndustry'];
		website_url?: string;
		phone?: IApiSchema['Phone'];
		email?: string;
		comment?: string;
		description?: string;
		owner_profile_picture?: string;
		owner_full_name?: string;
		owner_email?: string;
		timezone_offset?: string;
		timezone_region?: string;
		work_hours?: IApiSchema['ICustomerWorkHours'];
		subscription_start_at?: string;
		next_payment_date?: string;
		counts?: IApiSchema['CurrentCounts'];
	};
	CustomerIndustry: 'general' | 'ecommerce' | 'logistics' | 'finance' | 'education' | 'travel' | 'real_estate' | 'construction' | 'it' | 'medical';
	CustomerNotificationStatus: 'active' | 'inactive';
	CustomerNotifications: {
		no_response?: IApiSchema['NotificationNoResponse'];
		new_chat_working_hours?: IApiSchema['NotificationNewChatWH'];
		new_chat_non_working_hours?: IApiSchema['NotificationNewChatNWH'];
	};
	CustomerPlanBrief: {
		id: number;
		titles: IApiSchema['PlanTitle'];
		features: IApiSchema['PlanFeatures'];
		prices: IApiSchema['PlanPrice'];
		status: IApiSchema['PlanStatus'];
		is_custom: boolean;
		created_at: string;
	};
	CustomerSchedule: {
		is_open?: boolean;
		start?: string;
		close?: string;
	};
	CustomerShort: {
		id?: number;
		alias?: string;
	};
	CustomerStatus: 'pending' | 'trial' | 'active' | 'inactive';
	DelayedMessage: {
		text?: string;
		delay?: number;
		status?: IApiSchema['MessageStatus'];
	};
	DelayedMessages: {
		first_message?: IApiSchema['DelayedMessage'];
		second_message?: IApiSchema['DelayedMessage'];
		idle_operator_open_chat?: IApiSchema['DelayedMessage'];
		idle_operator_new_chat?: IApiSchema['DelayedMessage'];
		idle_guest?: IApiSchema['DelayedMessage'];
	};
	DepartmentBrief: {
		id: number;
		name: string;
		members?: IApiSchema['IMemberShort'][];
		status: IApiSchema['DepartmentStatus'];
	};
	DepartmentDetailed: {
		id: number;
		name: string;
		members?: IApiSchema['IMemberShort'][];
		status: IApiSchema['DepartmentStatus'];
	};
	DepartmentShort: {
		id?: number;
		name?: string;
	};
	DepartmentStatus: 'active' | 'inactive';
	FolderCreateRequestSchema: {
		name: string;
		status: IApiSchema['KnowledgeBaseInstanceStatusEnum'];
		widget_license_id_list: string[];
	};
	FolderCreateResponseSchema: {
		id: number;
		name: string;
		widget_license_id_list: string[];
		status: string;
		created_by: number;
	};
	FolderResponseSchema: {
		id: number;
		name: string;
	};
	FolderSchema: {
		id: number;
		name: string;
		articles?: IApiSchema['ArticleSchema'][];
	};
	FoldersListResponseSchema: {
		folders: IApiSchema['FolderResponseSchema'][];
	};
	FormEmail: {
		email: string;
	};
	FormStatusUpdate: {
		customer_id: number;
		is_active: boolean;
	};
	HTTPValidationError: {
		detail?: IApiSchema['ValidationError'][];
	};
	HealthCheck: {
		project?: string;
		platform?: string;
		role?: IApiSchema['Role'] | string;
		api?: {
			[key: string]: string | number;
		};
		at: string;
	};
	IChannelCallback: {
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelCallbackStatus: {
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelFacebook: {
		userID?: string;
		accessToken?: string;
		access_token?: string;
		page_name?: string;
		page_id?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelFacebookStatus: {
		page_name?: string;
		page_id?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelInstagram: {
		userID?: string;
		accessToken?: string;
		access_token?: string;
		page_name?: string;
		page_id?: string;
		fb_page_id?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelInstagramStatus: {
		page_name?: string;
		page_id?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelLiveChat: {
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelLiveChatStatus: {
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelTelegram: {
		bot_name?: string;
		bot_url?: string;
		token?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelTelegramStatus: {
		bot_name?: string;
		bot_url?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelViber: {
		bot_name?: string;
		bot_url?: string;
		token?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannelViberStatus: {
		bot_name?: string;
		bot_url?: string;
		status?: IApiSchema['ChannelStatus'];
	};
	IChannels: {
		live_chat?: IApiSchema['IChannelLiveChat'];
		callback?: IApiSchema['IChannelCallback'];
		telegram?: IApiSchema['IChannelTelegram'];
		viber?: IApiSchema['IChannelViber'];
		facebook?: IApiSchema['IChannelFacebook'];
		instagram?: IApiSchema['IChannelInstagram'];
	};
	IChannelsStatuses: {
		live_chat?: IApiSchema['IChannelLiveChatStatus'];
		callback?: IApiSchema['IChannelCallbackStatus'];
		telegram?: IApiSchema['IChannelTelegramStatus'];
		viber?: IApiSchema['IChannelViberStatus'];
		facebook?: IApiSchema['IChannelFacebookStatus'];
		instagram?: IApiSchema['IChannelInstagramStatus'];
	};
	IChatEvent: {
		type: IApiSchema['ChatEvent'];
		operator_id?: number;
		data?: Record<string, never>;
	};
	IChatEventBrief: {
		id: number;
		chat_id: number;
		chat_status: IApiSchema['ChatStatus'];
		type: IApiSchema['ChatEvent'];
		data: IApiSchema['IChatEvent'] | Record<string, never>;
		at: string;
		operator_id?: number;
		operator_name?: string;
		operator_pic?: string;
	};
	ICustomerWorkHours: {
		monday?: IApiSchema['CustomerSchedule'];
		tuesday?: IApiSchema['CustomerSchedule'];
		wednesday?: IApiSchema['CustomerSchedule'];
		thursday?: IApiSchema['CustomerSchedule'];
		friday?: IApiSchema['CustomerSchedule'];
		saturday?: IApiSchema['CustomerSchedule'];
		sunday?: IApiSchema['CustomerSchedule'];
	};
	IMemberShort: {
		id?: number;
		full_name?: string;
		profile_picture?: string;
	};
	IWidgetAutoReplaySettings: {
		live_chat_delayed?: IApiSchema['DelayedMessages'];
		all_channels_instant?: IApiSchema['InstantMessages'];
	};
	IWidgetDepartments: {
		is_selected?: boolean;
		values?: number[];
	};
	IWidgetFormField: {
		is_visible?: boolean;
		is_required?: boolean;
	};
	IWidgetPrivacyPolicy: {
		is_required?: boolean;
		document_url?: string;
	};
	IWidgetSettings: {
		color?: string;
		is_hide_branding?: boolean;
		button_style?: string;
		button_placement?: IApiSchema['WidgetPosition'];
		callback_button_style?: string;
		callback_button_placement?: IApiSchema['WidgetPosition'];
		is_form_displayed?: boolean;
		form_fields?: IApiSchema['IWidgetUserForm'];
		departments?: IApiSchema['IWidgetDepartments'];
		privacy_policy?: IApiSchema['IWidgetPrivacyPolicy'];
	};
	IWidgetUserForm: {
		name?: IApiSchema['IWidgetFormField'];
		email?: IApiSchema['IWidgetFormField'];
		phone?: IApiSchema['IWidgetFormField'];
	};
	InstantMessage: {
		text?: string;
		status?: IApiSchema['MessageStatus'];
	};
	InstantMessages: {
		first_message?: IApiSchema['InstantMessage'];
		second_message?: IApiSchema['InstantMessage'];
		non_working_hours?: IApiSchema['InstantMessage'];
	};
	KnowledgeBaseArticleInteractionTypeEnum: 'like' | 'dislike' | 'view' | 'read' | 'time_spent';
	KnowledgeBaseArticlesFoldersResponseSchema: {
		folders?: IApiSchema['FolderSchema'][];
		articles?: IApiSchema['ArticleSchema'][];
	};
	KnowledgeBaseKeywordsResponseSchema: {
		id: string;
		title: string;
	};
	KnowledgeBaseWidgetsResponseSchema: {
		license_id: string;
		name: string;
	};
	KnowledgeBaseInstanceStatusEnum: 'active' | 'disabled' | 'deleted';
	Language: 'uk' | 'en' | 'ru';
	LimitOffsetPage_AdminPlanBrief_: {
		items: IApiSchema['AdminPlanBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_BulkBrief_: {
		items: IApiSchema['BulkBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_ChatBrief_: {
		items: IApiSchema['ChatBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_ContactBrief_: {
		items: IApiSchema['ContactBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_CustomerBrief_: {
		items: IApiSchema['CustomerBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_CustomerPlanBrief_: {
		items: IApiSchema['CustomerPlanBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_DepartmentBrief_: {
		items: IApiSchema['DepartmentBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_IChatEventBrief_: {
		items: IApiSchema['IChatEventBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_MemberBrief_: {
		items: IApiSchema['MemberBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_MetricBrief_: {
		items: IApiSchema['MetricBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_PaymentBrief_: {
		items: IApiSchema['PaymentBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_TemplateBrief_: {
		items: IApiSchema['TemplateBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	LimitOffsetPage_WidgetBrief_: {
		items: IApiSchema['WidgetBrief'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	MemberBrief: {
		id: number;
		full_name: string;
		email: string;
		profile_picture: string;
		rating: number;
		role?: IApiSchema['MemberRole'];
		lang: IApiSchema['Language'];
		departments?: IApiSchema['DepartmentShort'][];
		status: IApiSchema['MemberStatus'];
	};
	MemberDetailed: {
		id: number;
		full_name: string;
		email: string;
		profile_picture: string;
		rating: number;
		role?: IApiSchema['MemberRole'];
		lang: IApiSchema['Language'];
		departments?: IApiSchema['DepartmentShort'][];
		status: IApiSchema['MemberStatus'];
		user_id: number;
		phone?: IApiSchema['Phone'];
		languages?: IApiSchema['Language'][];
		messengers?: string[];
	};
	MemberInviteUrl: {
		url: string;
	};
	MemberRole: 'system_administrator' | 'customer_owner' | 'customer_administrator' | 'operator';
	MemberStatus: 'pending' | 'active' | 'inactive' | 'pending_inactive';
	MessageResponse: {
		message: string;
	};
	MessageStatus: 'active' | 'inactive';
	MetricBrief: {
		id: number;
		chat_id: number;
		contact_id: number;
		customer_id: number;
		member_id: number;
		type: IApiSchema['ChatMetric'];
		value: number;
	};
	MetricTopValues: {
		value?: string;
		quantity: number;
	};
	MetricsAggregated: {
		type: IApiSchema['ChatMetric'];
		count?: number;
		max?: number;
		min?: number;
		avg?: number;
	};
	NotificationNewChatNWH: {
		status?: IApiSchema['CustomerNotificationStatus'];
		operators?: boolean;
		administrators?: boolean;
		specified_emails?: boolean;
		emails?: string[];
	};
	NotificationNewChatWH: {
		status?: IApiSchema['CustomerNotificationStatus'];
		delay?: number;
		operators?: boolean;
		administrators?: boolean;
		specified_emails?: boolean;
		emails?: string[];
	};
	NotificationNoResponse: {
		status?: IApiSchema['CustomerNotificationStatus'];
		delay?: number;
		chat_owner?: boolean;
		administrators?: boolean;
		specified_emails?: boolean;
		emails?: string[];
	};
	PaymentBrief: {
		id: number;
		amount?: number;
		created_at: string;
		currency: IApiSchema['Currency'];
		customer_id: number;
		customer_name: string;
		details?: Record<string, never> | string;
		order_reference: string;
		plan_titles: IApiSchema['PlanTitle'];
		status: IApiSchema['PaymentStatus'];
		type?: IApiSchema['PaymentType'];
	};
	PaymentDetailed: {
		id: number;
		amount?: number;
		created_at: string;
		currency: IApiSchema['Currency'];
		customer_id: number;
		customer_name: string;
		details?: Record<string, never> | string;
		order_reference: string;
		plan_titles: IApiSchema['PlanTitle'];
		status: IApiSchema['PaymentStatus'];
		type?: IApiSchema['PaymentType'];
		plan_id: number;
		subscription_id?: number;
		current_subscription_end_at?: string;
		wfp_at?: string;
		wfp_subscription_end_at?: string;
	};
	PaymentStatus: 'approved' | 'pending' | 'declined';
	PaymentType: 'manual' | 'card_check' | 'system' | 'daily' | 'monthly' | 'annually';
	Phone: string | Record<string, never>;
	PlanFeatures: {
		is_branding?: boolean;
		is_customization?: boolean;
		is_support?: boolean;
		templates?: number;
		users?: number;
		widgets?: number;
		channels?: number;
	};
	PlanPrice: {
		price_monthly?: IApiSchema['Price'];
		price_annually?: IApiSchema['Price'];
	};
	PlanStatus: 'active' | 'inactive';
	PlanSubscriptionDetailed: {
		plan_id: number;
		customer_id: number;
		type: IApiSchema['PaymentType'];
		start_at?: string;
		expire_at?: string;
		limits: IApiSchema['app__models__plan_subscription_model__SubscriptionLimits'];
		status: IApiSchema['PlanSubscriptionStatus'];
		titles: IApiSchema['PlanTitle'];
		prices: IApiSchema['PlanPrice'];
		counts?: IApiSchema['CurrentCounts'];
	};
	PlanSubscriptionForm: {
		payment_type: IApiSchema['PaymentType'];
		status: IApiSchema['PlanSubscriptionStatus'];
		customer_id: number;
		plan_id: number;
		expire_at?: string;
		start_at?: string;
	};
	PlanSubscriptionStatus: 'active' | 'done' | 'canceled' | 'pending';
	PlanTitle: {
		uk?: string;
		en?: string;
		ru?: string;
	};
	Price: {
		UAH?: number;
		USD?: number;
	};
	ResponseOk: {
		status?: string;
	};
	Role: 'system' | 'administrator' | 'manager' | 'chat';
	TemplateBrief: {
		id: number;
		name?: string;
		body?: string;
		operator_id?: number;
		status: IApiSchema['TemplateStatus'];
	};
	TemplateDetailed: {
		id: number;
		name?: string;
		body?: string;
		operator_id?: number;
		status: IApiSchema['TemplateStatus'];
	};
	TemplateStatus: 'active' | 'inactive';
	UpdateBulk: {
		name?: string;
		message?: string;
		files?: Record<string, never>[];
		tags?: string[];
		channels?: IApiSchema['ChatChannelType'][];
		is_scheduled?: boolean;
		scheduled_at?: string;
		status?: IApiSchema['BulkStatus'];
		result?: Record<string, never>;
	};
	UpdateContactDetailed: {
		full_name?: string;
		email?: string;
		phone?: string;
		languages?: IApiSchema['Language'][];
		tags?: string[];
		comment?: string;
		origin?: string;
		browser?: string;
		browser_lang?: string;
		device?: string;
		device_os?: string;
		first_message_at?: string;
		timezone?: string;
	};
	UpdateCustomerDetailed: {
		alias?: string;
		website_url?: string;
		phone?: string;
		email?: string;
		comment?: string;
		description?: string;
		customer_tags?: string[];
		industry?: IApiSchema['CustomerIndustry'];
		plan_id?: number;
		subscription_id?: number;
		status?: IApiSchema['CustomerStatus'];
		timezone_offset?: string;
		timezone_region?: string;
		work_hours?: IApiSchema['ICustomerWorkHours'];
		notifications?: IApiSchema['CustomerNotifications'];
	};
	UpdateDepartment: {
		name?: string;
		member_ids?: number[];
		status?: IApiSchema['DepartmentStatus'];
	};
	UpdateMemberDetailed: {
		role?: IApiSchema['MemberRole'];
		department_ids?: number[];
		phone?: string;
		full_name?: string;
		profile_picture?: string;
		lang?: IApiSchema['Language'];
		languages?: IApiSchema['Language'][];
		messengers?: string[];
		status?: IApiSchema['MemberStatus'];
	};
	UpdatePlan: {
		titles?: IApiSchema['PlanTitle'];
		features?: IApiSchema['PlanFeatures'];
		prices?: IApiSchema['PlanPrice'];
		status?: IApiSchema['PlanStatus'];
		is_custom?: boolean;
		customer_ids?: number[];
	};
	UpdateTemplate: {
		name?: string;
		body?: string;
		operator_id?: number;
		status?: IApiSchema['TemplateStatus'];
	};
	UpdateWidget: {
		name?: string;
		license_id?: string;
		channels?: IApiSchema['IChannels'];
		settings?: IApiSchema['IWidgetSettings'];
		status?: IApiSchema['WidgetStatus'];
		auto_reply_settings?: IApiSchema['IWidgetAutoReplaySettings'];
	};
	ValidationError: {
		loc: (string | number)[];
		msg: string;
		type: string;
	};
	WidgetBrief: {
		name: string;
		license_id?: string;
		status?: IApiSchema['WidgetStatus'];
		install_status?: IApiSchema['WidgetInstallStatus'];
		channels?: IApiSchema['IChannelsStatuses'];
		id: number;
	};
	WidgetDetailed: {
		name: string;
		license_id?: string;
		status?: IApiSchema['WidgetStatus'];
		install_status?: IApiSchema['WidgetInstallStatus'];
		channels?: IApiSchema['IChannels'];
		id: number;
		customer_id: number;
		is_active: boolean;
		settings?: IApiSchema['IWidgetSettings'];
		auto_reply_settings?: IApiSchema['IWidgetAutoReplaySettings'];
	};
	WidgetInstallStatus: 'installed' | 'not_installed';
	WidgetPosition: 'left' | 'right';
	WidgetResponseSchema: {
		license_id: string;
		name: string;
	};
	WidgetStatus: 'active' | 'inactive';
	WidgetsListResponseSchema: {
		widgets: IApiSchema['WidgetResponseSchema'][];
	};
	'app__api__subapps__crm__api__contact_api__LimitOffsetPage[TagShort]': {
		items: IApiSchema['app__code__tag__tag_schema__TagShort'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	'app__api__subapps__crm__api__customer_api__LimitOffsetPage[TagShort]': {
		items: IApiSchema['app__code__customer_tag__customer_tag_schema__TagShort'][];
		total: number;
		limit?: number;
		offset?: number;
	};
	app__cache__schemas__current_user_schema__SubscriptionLimits: {
		is_branding?: boolean;
		is_customization?: boolean;
		is_support?: boolean;
		templates?: number;
		users?: number;
		widgets?: number;
	};
	app__code__customer_tag__customer_tag_schema__TagShort: {
		id?: number;
		name?: string;
	};
	app__code__tag__tag_schema__TagShort: {
		id?: number;
		name?: string;
	};
	app__models__plan_subscription_model__SubscriptionLimits: {
		is_branding?: boolean;
		is_customization?: boolean;
		is_support?: boolean;
		channels?: number;
		templates?: number;
		users?: number;
		widgets?: number;
	};
}

export interface IPopupAlert {
	id?: string
	type?: 'default' | 'new' | 'success' | 'warning' | 'error';
	contentKey?: string
	contentParams?: Record<string, string>
	content?: string
	iconName?: string
	iconProps?: Record<string, string>
	timeout?: number
	stacked?: boolean
	withCloseButton?: boolean
	className?: string
	onCloseCustom?: () => void
}

export interface IPropsSvgIcon {
	width?: string | number;
	height?: string | number;
	fill?: string;
	fillColor?: string;
	checkboxColor?: string;
	stroke?: string;
	opacity?: string;
	wrapperColor?: string;
	strokeWidth?: string;
}

export enum LocalStorageKeys {
	soundNotifications = 'soundNotifications',
	pushNotifications = 'pushNotifications',
	rightDrawerState = 'rightDrawerState',
	rotateMessageShown = 'rotateMessageShown',
	explPanelStates = 'explPanelStates',
	menuState = 'menuState',
	newChatHistoryNotifications = 'newChatHistoryNotifications',
	subscriptionEndsAlertClosed = 'subscriptionEndsAlertClosed',
	isUserOnline = 'isUserOnline',
}

export type NameTypeIcon =
	| 'arrowSync'
	| 'article'
	| 'attachSign'
	| 'autoMessages'
	| 'bell'
	| 'book'
	| 'books'
	| 'briefcase'
	| 'bulkMessaging'
	| 'calendar'
	| 'calendar2'
	| 'calendarWithDays'
	| 'callback'
	| 'callbackIcon'
	| 'channels'
	| 'chats'
	| 'checkMailbox'
	| 'checkboxChecked'
	| 'checkboxUnchecked'
	| 'chevronDown'
	| 'chevronDownThin'
	| 'chevronUp'
	| 'chevronUpThin'
	| 'clock'
	| 'clockLong'
	| 'clockReaction'
	| 'coins'
	| 'contactCard'
	| 'contacts'
	| 'copy'
	| 'creditCard'
	| 'cross'
	| 'crossBubble'
	| 'customers'
	| 'customization'
	| 'dashboard'
	| 'deleteAccount'
	| 'deleteCompany'
	| 'doggySign'
	| 'dollarSign'
	| 'download'
	| 'durationClock'
	| 'emptyChatWidgets'
	| 'emptyDepartments'
	| 'emptyFolder'
	| 'emptyPayments'
	| 'emptySubcriptionPlans'
	| 'equalizer'
	| 'eyeSign'
	| 'eyeSignCross'
	| 'facebookIcon'
	| 'facebookLetter'
	| 'fileSign'
	| 'flagEN'
	| 'flagPL'
	| 'flagRU'
	| 'flagUK'
	| 'floatingDots'
	| 'floppy'
	| 'folder'
	| 'forward'
	| 'forwardToDepartment'
	| 'forwarded'
	| 'google'
	| 'hamburgerMenu'
	| 'helpCenter'
	| 'infoCircle'
	| 'inputError'
	| 'instagramIcon'
	| 'invitePeople'
	| 'invoices'
	| 'linkSign'
	| 'live_chatIcon'
	| 'loginArrow'
	| 'logo'
	| 'logoSymbol'
	| 'logoSymbolFilled'
	| 'logout'
	| 'mailSend'
	| 'members'
	| 'messages'
	| 'monitor'
	| 'multiUsers'
	| 'navMenuToggleArrow'
	| 'noChats'
	| 'noTeamChat'
	| 'noTeamChatMessages'
	| 'off'
	| 'offline'
	| 'paginationArrow'
	| 'palette'
	| 'passwordLocked'
	| 'passwordReset'
	| 'peopleSign'
	| 'phone'
	| 'planet'
	| 'plans'
	| 'plus'
	| 'plusCircle'
	| 'print'
	| 'profileEdit'
	| 'questionCircle'
	| 'radioChecked'
	| 'radioUnchecked'
	| 'requestSent'
	| 'searchSign'
	| 'settings'
	| 'signalIcon'
	| 'skibbleIcon'
	| 'skibbleTextLogo'
	| 'smile'
	| 'starRequiredSign'
	| 'starSign'
	| 'state'
	| 'statusActive'
	| 'statusActiveWithError'
	| 'statusDeleted'
	| 'statusInProgress'
	| 'statusPending'
	| 'statusTrial'
	| 'subscription'
	| 'tag'
	| 'teamChat'
	| 'telegramIcon'
	| 'templates'
	| 'templatesEmpty'
	| 'trashCan'
	| 'twitterSign'
	| 'typeBriefcase'
	| 'typeBriefcaseWidget'
	| 'typeBubble'
	| 'typeBubbleWidget'
	| 'typeBubbles'
	| 'typeBubblesWidget'
	| 'typeCallback'
	| 'typeCallbackWidget'
	| 'typePDF'
	| 'typeQuestion'
	| 'typeQuestionWidget'
	| 'typeSkibble'
	| 'typeSkibbleWidget'
	| 'typeTXT'
	| 'upload'
	| 'user'
	| 'users'
	| 'viberIcon'
	| 'warningSign'
	| 'whatsappIcon'
	| 'widgets'
	| 'zoomArrows'
	| 'zoomSign'
	| 'pendingInactive';

export type ApiUrlType =
	| 'billingPlans'
	| 'bulkMessaging'
	| 'bulkContacts'
	| 'subscriptionPlans'
	| 'subscriptionPlansByCustomer'
	| 'cancelSubscription'
	| 'chats'
	| 'chatDetails'
	| 'chatFoundNear'
	| 'contacts'
	| 'contactsOnline'
	| 'countries'
	| 'currentCompany'
	| 'currentUser'
	| 'customers'
	| 'customer_tags'
	| 'customersInvoice'
	| 'deleteCompany'
	| 'departments'
	| 'payments'
	| 'templates'
	| 'widgets'
	| 'emailConfirm'
	| 'invite'
	| 'inviteLink'
	| 'inviteResend'
	| 'inviteDetails'
	| 'inviteSignup'
	| 'knowledgeBase'
	| 'knowledgeBaseFolder'
	| 'knowledgeBaseArticle'
	| 'knowledgeBaseKeywords'
	| 'knowledgeBaseSearch'
	| 'knowledgeBaseUpdatePosition'
	| 'members'
	| 'knowledgeBaseUpdatePosition'
	| 'refreshToken'
	| 'requestResetPassword'
	| 'widgetsKnowledgeBase'
	| 'resetPassword'
	| 'updatePassword'
	| 'signIn'
	| 'signUp'
	| 'tags'
	| 'tokenRefresh'
	| 'fileUrl'
	| 'filesUpload'
	| 'statistics'
	| 'statisticMetric'
	| 'updateCurrentPlan'
	| 'wayforpayServiceUrl';

// todo: need remove this shit!
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IDummyData = any

// todo: need remove this shit!
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IRefType = any

export interface IAction extends IDummyData {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: any;
}

export interface ICommonState {
	isNavMenuExtended: boolean,
	isDrawerExtended: boolean,
	popupAlerts: IPopupAlert[],
}

export interface IDataState {
	inProgress: null | boolean,
	isDataInitialized: boolean,
	statisticMetric?: null,
	statistics?: null,
	type: '',
	error: boolean,
	total_items: null | number,
	customers?: IApiSchema['CustomerBrief'][],
	subscriptionPlansByCustomer?: IApiSchema['LimitOffsetPage_CustomerPlanBrief_']
	widgets?: IDummyData,
	bulkMessaging?: IDummyData,
	countries?: IDummyData,
	payments?: IDummyData,
	members?: {
		id: number,
		full_name: string,
		email: string,
		profile_picture: string,
		rating: number,
		role: IApiSchema['MemberRole'],
		lang: string,
		departments: [
			{
				id: null | number,
				name: null | string,
			}
		],
		status: IApiSchema['MemberStatus']
	}[],
	customer_tags?: IDummyData,
	subscriptionPlans?: IDummyData,
	contacts?: IDummyData,
	templates?: IDummyData,
	departments?: IDummyData,
	knowledgeBase?: {folders: IApiSchema['FolderSchema'][], articles: IApiSchema['ArticleSchema'][]},
	bulkContacts?: IDummyData,
	tags?: IDummyData,
	knowledgeBaseFolder?: IApiSchema['FolderSchema'],
	knowledgeBaseKeywords?: IApiSchema['KnowledgeBaseKeywordsResponseSchema'],
	widgetsKnowledgeBase?:IApiSchema['KnowledgeBaseWidgetsResponseSchema'],
}

export interface IUserState {
	token: null,
	details: IApiSchema['CurrentUser'],
	subscriptionDetails: IApiSchema['CurrentCompany'],
	inviteDetails: {
		email: '',
		company_name: '',
	},
	inProgress: boolean,
	resetPassInProgress: boolean,
	error: boolean,
	isAuthenticated: null | boolean,
	isAppInitialized: boolean,
	isUserOnline: boolean,
	email_verified: undefined | boolean,
}

export interface ICompanyState {
	inProgress: boolean,
	error: null,
	general: IApiSchema['CustomerDetailed'],
	billingPlans: IApiSchema['CustomerPlanBrief'][],
}

export interface ISidebarState {
	details: null | {
		channels: IApiSchema['IChannelsStatuses'];
		full_name?: string;
		profile_picture?: string;
		auto_reply_settings?: string;
		prices?: IDummyData;
		titles?: IDummyData;
		customers?: { id: string }[];
		email?: string
		type?: string
		owner?: {
			full_name?: string
		}
		details?: {
			issuerBankName?: string
			issuerBankCountry?: string
		}
		status: string,
		scheduled_at?: string,
		id?: string,
		owner_email?: string,
		order_reference?: string,
		amount?: string,
		currency?: string,
		company_logo?: string,
		owner_profile_picture?: string,
		plan_id?: string,
		alias?: string,
		company_name?: string,
		created_at?: string,
		customer_id?: string,
		owner_full_name?: string,
		subscription_expire_at?: string,
	} | IDummyData,
	initial: null,
	id?: null,
	articleContent?: string;
	sidebarInProgress: boolean,
	error: null | boolean,
}

export interface IChatsState {
	new: IApiSchema['ChatBrief'][],
	open: IApiSchema['ChatBrief'][],
	closed: IApiSchema['ChatBrief'][],
	closedCount: number,
	inProgressClosed: boolean,
	inProgressNotClosed: undefined | boolean,
	inProgressSetEventToHistory: boolean,
	contactsOnline: null | Record<string, boolean>,
	chatInfo: IApiSchema['ChatBrief'],
	chatHistory: { type: string, id: number, operator_id?: number }[],
	chatHistoryCount: number,
	inProgress?: boolean | undefined,
	inProgressHistory: boolean,
	inProgressHistoryOnScroll: boolean,
	inProgressSearchMessage: boolean,
	allMessagesLoadedUp: boolean,
	allMessagesLoadedDown: boolean,
	isHistoryFirstLoadWithPictures?: boolean,
	limit?: number,
	foundMessagesIds: [],
	error: null | boolean,
	newChatHistoryNotifications: undefined | Record<string, number[]>,
	chatMessage: string,
	eventJustSent: string,
	lastMessageId: null,
}

export interface IFileUploaded {
	file?: string;
	file_name: string;
	file_size: number;
	file_code: string;
	type: string;
	url?: string;
}

export interface IFileUploadState {
	errorLatest: null,
	inProgress: boolean,
	errors: IDummyData,
	filesUploaded: IDummyData,
	filesChosen: IDummyData,
	filesInProgress: IDummyData,
	fileUrlsLoadedByCode: IDummyData,
}

export interface IFormsState {
	formValues: IDummyData,
}

export interface IStore {
	common: ICommonState;
	data: IDataState;
	user: IUserState;
	company: ICompanyState;
	sidebar: ISidebarState;
	chats: IChatsState;
	fileUpload: IFileUploadState;
	forms: IFormsState;
}

export type TNavigate = (path: string | { pathname: string, search: string }) => void;

export type ChatStatus = 'new' | 'open' | 'closed';

export interface IKnowledgeBaseUpdatePositionServerRequest {
	'articles': {
		'id': number,
		'folder_id': number | null,
		'position': number
	}[],
	'folders': {
		'id': number,
		'position': number
	}[]
}