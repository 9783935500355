import { all } from 'redux-saga/effects';

import authSaga from './sagas/auth';
import companySaga from './sagas/company';
import initSaga from './sagas/init';
import dataSaga from './sagas/data';
import sidebarSaga from './sagas/sidebar';
import chatsSaga from './sagas/chats';
import fileUploadSaga from './sagas/fileUpload';

export default function* rootSaga() {
	yield all([
		authSaga(),
		companySaga(),
		initSaga(),
		dataSaga(),
		sidebarSaga(),
		chatsSaga(),
		fileUploadSaga(),
	]);
}