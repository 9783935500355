import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { useTheme } from 'styled-components';
import { useFacebookSdkInit } from './hooks/useFacebookSdkInit';
import { ScreenContext, useScreenContext } from './hooks/useScreenContext';
import Routing from './Routing';

function App() {
	const theme = useTheme();

	const screenContextValues = useScreenContext(theme);

	useFacebookSdkInit();

	return (
		<Suspense fallback={null}>
			<ScreenContext.Provider value={screenContextValues}>
				<Routing />
			</ScreenContext.Provider>
		</Suspense>
	);
}

export default Sentry.withProfiler(App);