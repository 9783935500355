import React from 'react';
import { arrowSync } from './icons/arrowSync';
import { article } from './icons/article';
import { attachSign } from './icons/attachSign';
import { autoMessages } from './icons/autoMessages';
import { bell } from './icons/bell';
import { book } from './icons/book';
import { books } from './icons/books';
import { briefcase } from './icons/briefcase';
import { bulkMessaging } from './icons/bulkMessaging';
import { calendar } from './icons/calendar';
import { calendar2 } from './icons/calendar2';
import { calendarWithDays } from './icons/calendarWithDays';
import { callback } from './icons/callback';
import { callbackIcon } from './icons/callbackIcon';
import { channels } from './icons/channels';
import { chats } from './icons/chats';
import { checkMailbox } from './icons/checkMailbox';
import { checkboxChecked } from './icons/checkboxChecked';
import { checkboxUnchecked } from './icons/checkboxUnchecked';
import { chevronDown } from './icons/chevronDown';
import { chevronDownThin } from './icons/chevronDownThin';
import { chevronUp } from './icons/chevronUp';
import { chevronUpThin } from './icons/chevronUpThin';
import { clock } from './icons/clock';
import { clockLong } from './icons/clockLong';
import { clockReaction } from './icons/clockReaction';
import { coins } from './icons/coins';
import { contactCard } from './icons/contactCard';
import { contacts } from './icons/contacts';
import { copy } from './icons/copy';
import { creditCard } from './icons/creditCard';
import { cross } from './icons/cross';
import { crossBubble } from './icons/crossBubble';
import { customers } from './icons/customers';
import { customization } from './icons/customization';
import { dashboard } from './icons/dashboard';
import { deleteAccount } from './icons/deleteAccount';
import { deleteCompany } from './icons/deleteCompany';
import { doggySign } from './icons/doggySign';
import { dollarSign } from './icons/dollarSign';
import { download } from './icons/download';
import { durationClock } from './icons/durationClock';
import { emptyChatWidgets } from './icons/emptyChatWidgets';
import { emptyDepartments } from './icons/emptyDepartments';
import { emptyFolder } from './icons/emptyFolder';
import { emptyPayments } from './icons/emptyPayments';
import { emptySubcriptionPlans } from './icons/emptySubcriptionPlans';
import { equalizer } from './icons/equalizer';
import { eyeSign } from './icons/eyeSign';
import { eyeSignCross } from './icons/eyeSignCross';
import { facebookIcon } from './icons/facebookIcon';
import { facebookLetter } from './icons/facebookLetter';
import { fileSign } from './icons/fileSign';
import { flagEN } from './icons/flagEN';
import { flagPL } from './icons/flagPL';
import { flagRU } from './icons/flagRU';
import { flagUK } from './icons/flagUK';
import { floatingDots } from './icons/floatingDots';
import { floppy } from './icons/floppy';
import { folder } from './icons/folder';
import { forward } from './icons/forward';
import { forwardToDepartment } from './icons/forwardToDepartment';
import { forwarded } from './icons/forwarded';
import { google } from './icons/google';
import { hamburgerMenu } from './icons/hamburgerMenu';
import { helpCenter } from './icons/helpCenter';
import { infoCircle } from './icons/infoCircle';
import { inputError } from './icons/inputError';
import { instagramIcon } from './icons/instagramIcon';
import { invitePeople } from './icons/invitePeople';
import { invoices } from './icons/invoices';
import { linkSign } from './icons/linkSign';
import { live_chatIcon } from './icons/live_chatIcon';
import { loginArrow } from './icons/loginArrow';
import { logo } from './icons/logo';
import { logoSymbol } from './icons/logoSymbol';
import { logoSymbolFilled } from './icons/logoSymbolFilled';
import { logout } from './icons/logout';
import { mailSend } from './icons/mailSend';
import { members } from './icons/members';
import { messages } from './icons/messages';
import { monitor } from './icons/monitor';
import { multiUsers } from './icons/multiUsers';
import { navMenuToggleArrow } from './icons/navMenuToggleArrow';
import { noChats } from './icons/noChats';
import { noTeamChat } from './icons/noTeamChat';
import { noTeamChatMessages } from './icons/noTeamChatMessages';
import { off } from './icons/off';
import { offline } from './icons/offline';
import { paginationArrow } from './icons/paginationArrow';
import { palette } from './icons/palette';
import { passwordLocked } from './icons/passwordLocked';
import { passwordReset } from './icons/passwordReset';
import { peopleSign } from './icons/peopleSign';
import { phone } from './icons/phone';
import { planet } from './icons/planet';
import { plans } from './icons/plans';
import { plus } from './icons/plus';
import { plusCircle } from './icons/plusCircle';
import { print } from './icons/print';
import { profileEdit } from './icons/profileEdit';
import { questionCircle } from './icons/questionCircle';
import { radioChecked } from './icons/radioChecked';
import { radioUnchecked } from './icons/radioUnchecked';
import { requestSent } from './icons/requestSent';
import { searchSign } from './icons/searchSign';
import { settings } from './icons/settings';
import { signalIcon } from './icons/signalIcon';
import { skibbleIcon } from './icons/skibbleIcon';
import { skibbleTextLogo } from './icons/skibbleTextLogo';
import { smile } from './icons/smile';
import { starRequiredSign } from './icons/starRequiredSign';
import { starSign } from './icons/starSign';
import { state } from './icons/state';
import { statusActive } from './icons/statusActive';
import { statusActiveWithError } from './icons/statusActiveWithError';
import { statusDeleted } from './icons/statusDeleted';
import { statusInProgress } from './icons/statusInProgress';
import { statusPending } from './icons/statusPending';
import { pendingInactive } from './icons/pendingInactive';
import { statusTrial } from './icons/statusTrial';
import { subscription } from './icons/subscription';
import { tag } from './icons/tag';
import { teamChat } from './icons/teamChat';
import { telegramIcon } from './icons/telegramIcon';
import { templates } from './icons/templates';
import { templatesEmpty } from './icons/templatesEmpty';
import { trashCan } from './icons/trashCan';
import { twitterSign } from './icons/twitterSign';
import { typeBriefcase } from './icons/typeBriefcase';
import { typeBriefcaseWidget } from './icons/typeBriefcaseWidget';
import { typeBubble } from './icons/typeBubble';
import { typeBubbleWidget } from './icons/typeBubbleWidget';
import { typeBubbles } from './icons/typeBubbles';
import { typeBubblesWidget } from './icons/typeBubblesWidget';
import { typeCallback } from './icons/typeCallback';
import { typeCallbackWidget } from './icons/typeCallbackWidget';
import { typePDF } from './icons/typePDF';
import { typeQuestion } from './icons/typeQuestion';
import { typeQuestionWidget } from './icons/typeQuestionWidget';
import { typeSkibble } from './icons/typeSkibble';
import { typeSkibbleWidget } from './icons/typeSkibbleWidget';
import { typeTXT } from './icons/typeTXT';
import { upload } from './icons/upload';
import { user } from './icons/user';
import { users } from './icons/users';
import { viberIcon } from './icons/viberIcon';
import { warningSign } from './icons/warningSign';
import { whatsappIcon } from './icons/whatsappIcon';
import { widgets } from './icons/widgets';
import { zoomArrows } from './icons/zoomArrows';
import { zoomSign } from './icons/zoomSign';
import { IPropsSvgIcon, NameTypeIcon } from '../types';

const Icons: Record<NameTypeIcon, React.FC<IPropsSvgIcon>> = {
	book,
	books,
	briefcase,
	checkboxChecked,
	checkboxUnchecked,
	checkMailbox,
	chevronDown,
	contactCard,
	cross,
	doggySign,
	eyeSign,
	eyeSignCross,
	pendingInactive,
	facebookLetter,
	google,
	inputError,
	loginArrow,
	logo,
	logoSymbol,
	logoSymbolFilled,
	mailSend,
	passwordLocked,
	peopleSign,
	planet,
	radioChecked,
	radioUnchecked,
	requestSent,
	signalIcon,
	skibbleTextLogo,
	starSign,
	state,
	statusActive,
	statusDeleted,
	twitterSign,
	chats,
	contacts,
	customers,
	dashboard,
	helpCenter,
	logout,
	members,
	settings,
	teamChat,
	templates,
	navMenuToggleArrow,
	hamburgerMenu,
	emptyFolder,
	paginationArrow,
	invitePeople,
	searchSign,
	statusPending,
	chevronUp,
	invoices,
	statusTrial,
	phone,
	dollarSign,
	tag,
	multiUsers,
	arrowSync,
	coins,
	calendar,
	linkSign,
	questionCircle,
	plusCircle,
	floppy,
	crossBubble,
	user,
	trashCan,
	telegramIcon,
	viberIcon,
	whatsappIcon,
	forward,
	forwardToDepartment,
	users,
	copy,
	floatingDots,
	facebookIcon,
	instagramIcon,
	live_chatIcon,
	skibbleIcon,
	forwarded,
	attachSign,
	smile,
	emptyDepartments,
	clock,
	creditCard,
	widgets,
	subscription,
	equalizer,
	emptyChatWidgets,
	customization,
	chevronDownThin,
	chevronUpThin,
	off,
	upload,
	typeTXT,
	typePDF,
	fileSign,
	download,
	noChats,
	warningSign,
	zoomSign,
	palette,
	zoomArrows,
	bell,
	deleteAccount,
	templatesEmpty,
	profileEdit,
	offline,
	monitor,
	passwordReset,
	typeSkibble,
	typeBubbles,
	typeBubble,
	typeQuestion,
	typeBriefcase,
	typeSkibbleWidget,
	typeBubblesWidget,
	typeBubbleWidget,
	typeQuestionWidget,
	typeBriefcaseWidget,
	durationClock,
	clockLong,
	clockReaction,
	starRequiredSign,
	emptyPayments,
	print,
	deleteCompany,
	plans,
	emptySubcriptionPlans,
	plus,
	flagEN,
	flagPL,
	flagUK,
	flagRU,
	autoMessages,
	infoCircle,
	bulkMessaging,
	messages,
	statusInProgress,
	calendar2,
	statusActiveWithError,
	callbackIcon,
	typeCallback,
	typeCallbackWidget,
	callback,
	calendarWithDays,
	noTeamChat,
	channels,
	folder,
	article,
	noTeamChatMessages,
};

export default Icons;