import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PrivateRoute from './wrappers/PrivateRoute';
import { useScreenContext } from './hooks/useScreenContext';

const BulkMessaging = React.lazy(() => import('./containers/BulkMessaging/BulkMessaging'));
const BulkMessagingEdit = React.lazy(() => import('./containers/BulkMessaging/panels/BulkMessagingEdit'));
const Chats = React.lazy(() => import('./containers/Chats/Chats'));
const ChatActionPanel = React.lazy(() => import('./containers/Chats/panels/ChatActionPanel'));
const Contacts = React.lazy(() => import('./containers/Contacts/Contacts'));
const ContactEdit = React.lazy(() => import('./containers/Contacts/panels/ContactEdit'));
const Customers = React.lazy(() => import('./containers/Customers/Customers'));
const CustomersRightPanel = React.lazy(() => import('./containers/Customers/panels/CustomersRightPanel'));
const Dashboard = React.lazy(() => import('./containers/Dashboard/Dashboard'));
const KnowledgeBase = React.lazy(() => import('./containers/KnowledgeBase/KnowledgeBase'));
const KnowledgeBaseActionPanel = React.lazy(() => import('./containers/KnowledgeBase/panels/KnowledgeBaseActionPanel'));
const MainWrapper = React.lazy(() => import('./containers/Layout/MainWrapper'));
const Members = React.lazy(() => import('./containers/Members/Members'));
const MembersRightPanel = React.lazy(() => import('./containers/Members/panels/MembersRightPanel'));
const MyCompany = React.lazy(() => import('./containers/MyCompany/MyCompany'));
const ChatWidgetCustomisation = React.lazy(() => import('./containers/MyCompanyChatWidgets/ChatWidgetCustomisation'));
const ChatWidgets = React.lazy(() => import('./containers/MyCompanyChatWidgets/ChatWidgets'));
const ChatWidgetsAutoMessages = React.lazy(() => import('./containers/MyCompanyChatWidgets/panels/ChatWidgetsAutoMessages'));
const ChatWidgetsCustomisationPanel = React.lazy(() => import('./containers/MyCompanyChatWidgets/panels/ChatWidgetsCustomisationPanel'));
const ChatWidgetsUpsert = React.lazy(() => import('./containers/MyCompanyChatWidgets/panels/ChatWidgetsUpsert'));
const MyCompanyDelete = React.lazy(() => import('./containers/MyCompanyDelete/MyCompanyDelete'));
const Departments = React.lazy(() => import('./containers/MyCompanyDepartments/Departments'));
const DepartmentUpsert = React.lazy(() => import('./containers/MyCompanyDepartments/panels/DepartmentUpsert'));
const MyCompanyGeneral = React.lazy(() => import('./containers/MyCompanyGeneral/MyCompanyGeneral'));
const MyCompanyHours = React.lazy(() => import('./containers/MyCompanyHours/MyCompanyHours'));
const MyCompanyNotifications = React.lazy(() => import('./containers/MyCompanyNotifications/MyCompanyNotifications'));
const Payments = React.lazy(() => import('./containers/MyCompanyPayments/Payments'));
const PaymentsDetails = React.lazy(() => import('./containers/MyCompanyPayments/panels/PaymentsDetails'));
const MyCompanySubscription = React.lazy(() => import('./containers/MyCompanySubscription/MyCompanySubscription'));
const NewPassword = React.lazy(() => import('./containers/NewPassword/NewPassword'));
const NotFound = React.lazy(() => import('./containers/NotFound/NotFound'));
const PasswordRecovery = React.lazy(() => import('./containers/PasswordRecovery/PasswordRecovery'));
const PasswordRecoverySent = React.lazy(() => import('./containers/PasswordRecoverySent/PasswordRecoverySent'));
const PrivacyPolicy = React.lazy(() => import('./containers/PrivacyPolicy/PrivacyPolicy'));
const RequestSent = React.lazy(() => import('./containers/RequestSent/RequestSent'));
const Settings = React.lazy(() => import('./containers/Settings/Settings'));
const SettingsDeleteAccount = React.lazy(() => import('./containers/SettingsDeleteAccount/SettingsDeleteAccount'));
const SettingsInvoices = React.lazy(() => import('./containers/SettingsInvoices/SettingsInvoices'));
const InvoiceDetails = React.lazy(() => import('./containers/SettingsInvoices/InvoiceDetails'));
const SettingsNotifications = React.lazy(() => import('./containers/SettingsNotifications/SettingsNotifications'));
const SettingsProfile = React.lazy(() => import('./containers/SettingsProfile/SettingsProfile'));
const SignIn = React.lazy(() => import('./containers/SignIn/SignIn'));
const SignUp = React.lazy(() => import('./containers/SignUp/SignUp'));
const SubscriptionPlans = React.lazy(() => import('./containers/SubscriptionPlans/SubscriptionPlans'));
const SubscriptionPlansEdit = React.lazy(() => import('./containers/SubscriptionPlans/panels/SubscriptionPlansEdit'));
const TeamChats = React.lazy(() => import('./containers/TeamChats/TeamChats'));
const Templates = React.lazy(() => import('./containers/Templates/Templates'));
const TermsAndConditions = React.lazy(() => import('./containers/TermsAndConditions/TermsAndConditions'));

const Routing = () => {
	const theme = useTheme();
	const screenContextValues = useScreenContext(theme);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/sign-in" element={<SignIn screenContext={screenContextValues} />} />
				<Route path="/password-recovery" element={<PasswordRecovery />} />
				<Route path="/password-request-sent" element={<PasswordRecoverySent />} />
				<Route path="/new-password" element={<NewPassword />} />
				<Route path="/confirm-email" element={<RequestSent />} />
				<Route path="/sign-up" element={<SignUp />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
				<Route
					path="/"
					element={(
						<PrivateRoute>
							<MainWrapper />
						</PrivateRoute>
					)}
				>
					<Route index element={<Dashboard />} />
					<Route path="/templates" element={<Templates />}>
						<Route path=":templateId" element={null} />
					</Route>

					<Route path="/members" element={<Members />}>
						<Route path=":rightPanelId" element={<MembersRightPanel />} />
					</Route>

					<Route path="/contacts" element={<Contacts />}>
						<Route path=":rightPanelId" element={<ContactEdit />} />
					</Route>

					<Route path="/bulk-messaging" element={<BulkMessaging />}>
						<Route path=":rightPanelId" element={<BulkMessagingEdit />} />
					</Route>

					<Route path="/customers" element={<Customers />}>
						<Route path=":rightPanelId" element={<CustomersRightPanel />}>
							<Route path=":action" element={null} />
						</Route>
					</Route>
					<Route path="/subscription-plans" element={<SubscriptionPlans />}>
						<Route path=":rightPanelId" element={<SubscriptionPlansEdit />} />
					</Route>
					<Route path="/payments" element={<Payments />}>
						<Route path=":rightPanelId" element={<PaymentsDetails />} />
					</Route>

					<Route path="/chats" element={<Chats />}>
						<Route path=":chatId" element={null} />
						<Route path=":chatId/:action" element={<ChatActionPanel />} />
						<Route path=":chatId/:action/:rightPanelId" element={<ContactEdit />} />
					</Route>
					<Route path="/team-chat" element={<TeamChats />}>
						<Route path=":chatId" element={null} />
					</Route>
					<Route path="/my-company" element={<MyCompany />}>
						<Route path="general" element={<MyCompanyGeneral />} />
						<Route path="hours-of-work" element={<MyCompanyHours />} />
						<Route path="notifications" element={<MyCompanyNotifications />} />
						<Route path="subscription" element={<MyCompanySubscription />} />
						<Route path="delete-company" element={<MyCompanyDelete />} />
					</Route>
					{/* my-company with right panel */}
					<Route path="/my-company/departments" element={<Departments />}>
						<Route path=":rightPanelId" element={<DepartmentUpsert />} />
					</Route>
					<Route path="/my-company/chat-widgets" element={<ChatWidgets />}>
						<Route path=":rightPanelId" element={<ChatWidgetsUpsert />} />
						<Route path="auto-messages/:rightPanelId" element={<ChatWidgetsAutoMessages />} />
					</Route>
					<Route path="/my-company/chat-customization" element={<ChatWidgetCustomisation />}>
						<Route path=":rightPanelId" element={<ChatWidgetsCustomisationPanel />} />
					</Route>

					<Route path="/my-company/payments" element={<Payments />}>
						<Route path=":rightPanelId" element={<PaymentsDetails />} />
					</Route>

					<Route path="/settings" element={<Settings />}>
						<Route index path="my-profile" element={<SettingsProfile />} />
						<Route path="notifications" element={<SettingsNotifications />} />
						<Route path="delete-account" element={<SettingsDeleteAccount />} />
					</Route>
					{/* settings with right panel */}
					<Route path="/settings/invoices" element={<SettingsInvoices />}>
						<Route path=":rightPanelId" element={<InvoiceDetails />} />
					</Route>

					<Route path="/knowledgebase" element={<KnowledgeBase />}>
						<Route path=":action/:rightPanelId" element={<KnowledgeBaseActionPanel />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</Route>
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Routing;