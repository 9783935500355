import { nanoid } from 'nanoid';
import {
	FIND_ALL_MESSAGES,
	GET_CHATS_LIST,
	GET_CHATS_LIST_ERROR,
	GET_CHATS_LIST_NOT_CLOSED,
	GET_CHATS_LIST_NOT_CLOSED_SUCCESS,
	GET_CHATS_LIST_SUCCESS,
	GET_CHAT_DETAILS,
	GET_CHAT_DETAILS_ERROR,
	GET_CHAT_DETAILS_ON_SCROLL,
	GET_CHAT_DETAILS_ON_SCROLL_SUCCESS,
	GET_CHAT_DETAILS_SUCCESS,
	GET_ONLINE_CONTACTS_SUCCESS,
	HIDE_CONTACT_TYPING,
	RESET_FOUND_MESSAGES,
	RESET_NEW_CHAT_HISTORY_NOTIFICATIONS,
	SEARCH_CHAT_MESSAGE,
	SEARCH_CHAT_MESSAGE_SUCCESS,
	SET_CHAT_MESSAGE,
	SET_CONTACT_NAME_TO_CHAT,
	SET_CONTACT_ONLINE_STATUS,
	SET_DATA_TYPE,
	SET_EVENT_TO_CHAT_HISTORY,
	SET_EVENT_TO_CHAT_HISTORY_ERROR,
	SET_EVENT_TO_CHAT_HISTORY_SUCCESS,
	SET_FOUND_MESSAGES_IDS,
	SET_IS_EVENT_JUST_SENT,
	SET_NEW_CHAT_HISTORY_NOTIFICATION,
	SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL,
	SIGN_OUT,
} from '../constants';
import { IAction, IChatsState } from '../../types';

const defaultState: IChatsState = {
	new: [],
	open: [],
	closed: [],
	closedCount: 0,
	inProgressClosed: false,
	inProgressNotClosed: undefined, // do not change undefined to false
	inProgressSetEventToHistory: false,
	contactsOnline: null,
	chatInfo: {
		// @ts-ignore
		status: '',
		// @ts-ignore
		contact_id: '',
		contact_uid: '',
		contact_name: '',
	},
	chatHistory: [],
	chatHistoryCount: 0,
	inProgressHistory: false,
	inProgressHistoryOnScroll: false,
	inProgressSearchMessage: false,
	allMessagesLoadedUp: false,
	allMessagesLoadedDown: true,
	foundMessagesIds: [],
	error: null,
	newChatHistoryNotifications: undefined,
	chatMessage: '',
	eventJustSent: '',
	lastMessageId: null,
};

type TStatus = 'new' | 'open' | 'closed';

export default (state = defaultState, action = {} as IAction): IChatsState => {
	switch (action.type) {
		case SET_CHAT_MESSAGE:
			return {
				...state,
				chatMessage: action.payload.message || '',
			};
		case GET_CHATS_LIST:
			return { ...state, inProgressClosed: true };
		case GET_CHATS_LIST_NOT_CLOSED:
			return {
				...state,
				inProgressNotClosed: true,
			};
		case GET_CHATS_LIST_SUCCESS: {
			const status = action?.payload?.status as TStatus;
			const isInfiniteScroll = action?.payload?.isInfiniteScroll;

			return {
				...state,
				inProgressClosed: false,
				[status]: isInfiniteScroll
					? [...state[status], ...action.payload.items]
					: action.payload?.items,
				closedCount: action?.payload?.total,
			};
		}
		case GET_CHATS_LIST_NOT_CLOSED_SUCCESS: {
			return {
				...state,
				inProgressNotClosed: false,
				...action.payload,
			};
		}
		case GET_ONLINE_CONTACTS_SUCCESS:
			return {
				...state,
				contactsOnline: action.payload?.length
					? action.payload.reduce((acc: Record<string, boolean>, curr: string) => {
						acc[curr] = true;
						return acc;
					}, {})
					: {},
			};
		case SET_CONTACT_ONLINE_STATUS:
			return {
				...state,
				contactsOnline: {
					...(state.contactsOnline || {}),
					[action.payload.contactId]: action.payload.isOnline,
				},
			};
		case GET_CHATS_LIST_ERROR:
		case GET_CHAT_DETAILS_ERROR:
		case SET_EVENT_TO_CHAT_HISTORY_ERROR:
			return {
				...state,
				inProgress: false,
				inProgressClosed: false,
				inProgressSetEventToHistory: false,
				error: true,
			};
		case SET_CONTACT_NAME_TO_CHAT: {
			const { contact_name, chat_id } = action.payload;

			const mapChats = (status: TStatus) => state[status].map((chat) => {
				if (chat.id === chat_id) {
					return { ...chat, contact_name };
				}
				return chat;
			});

			return {
				...state,
				chatInfo:
					state.chatInfo.id === chat_id
						? { ...state.chatInfo, contact_name }
						: state.chatInfo,
				new: mapChats('new'),
				open: mapChats('open'),
				closed: mapChats('closed'),
			};
		}
		case GET_CHAT_DETAILS:
			return {
				...state,
				inProgressHistory: true,
				allMessagesLoadedUp: false,
			};
		case GET_CHAT_DETAILS_SUCCESS:
			return {
				...state,
				inProgressHistory: false,
				chatHistory: action.payload.history,
				chatInfo: action.payload.chatData || state.chatInfo,
				chatHistoryCount: action.payload.total, // || state.chatHistoryCount
				allMessagesLoadedUp: action.payload.allMessagesLoadedUp,
				allMessagesLoadedDown: action.payload.allMessagesLoadedDown,
				limit: action.payload.limit,
				isHistoryFirstLoadWithPictures: action.payload.isHistoryFirstLoadWithPictures,
				lastMessageId: action.payload.lastMessageId || state.lastMessageId,
			};
		case GET_CHAT_DETAILS_ON_SCROLL:
			return {
				...state,
				inProgressHistoryOnScroll: true,
			};
		case GET_CHAT_DETAILS_ON_SCROLL_SUCCESS: {
			const { isScrollUp, history } = action.payload;

			const newHistory = [
				...(isScrollUp ? history : []),
				...state.chatHistory,
				...(!isScrollUp ? history : []),
			];
			const allItemsLoaded = state.chatHistoryCount === newHistory.length;

			return {
				...state,
				inProgressHistoryOnScroll: false,
				chatHistory: newHistory,
				[isScrollUp ? 'allMessagesLoadedUp' : 'allMessagesLoadedDown']:
          action.payload.allMessagesLoadedInScrollDirection,
				...(allItemsLoaded ? { allMessagesLoadedUp: true, allMessagesLoadedDown: true } : {}),
			};
		}
		case FIND_ALL_MESSAGES:
			return {
				...state,
			};
		case SEARCH_CHAT_MESSAGE:
			return {
				...state,
				allMessagesLoadedUp: false,
				allMessagesLoadedDown: false,
				inProgressSearchMessage: true,
			};
		case SEARCH_CHAT_MESSAGE_SUCCESS: {
			const allItemsLoaded = state.chatHistoryCount === action.payload.history.length;

			const newValues = {
				allMessagesLoadedUp: allItemsLoaded || action.payload.allMessagesLoadedUp,
				allMessagesLoadedDown: allItemsLoaded || action.payload.allMessagesLoadedDown,
				chatHistory: action.payload.history,
			};
			return {
				...state,
				inProgressSearchMessage: false,
				...(action.payload.history?.length ? newValues : {}),
			};
		}
		case SET_FOUND_MESSAGES_IDS:
			return {
				...state,
				foundMessagesIds: action.payload.reverse(),
			};
		case RESET_FOUND_MESSAGES:
			return {
				...state,
				foundMessagesIds: [],
			};
		case SET_EVENT_TO_CHAT_HISTORY:
			return {
				...state,
				inProgressSetEventToHistory: true,
			};
		case SET_IS_EVENT_JUST_SENT:
			return {
				...state,
				eventJustSent: action.payload.eventJustSent,
			};
		case HIDE_CONTACT_TYPING: {
			return {
				...state,
				chatHistory: state.chatHistory.filter((item) => item.type !== 'contact_typing'),
			};
		}
		case SET_EVENT_TO_CHAT_HISTORY_SUCCESS: {
			const {
				chatsToDelete = [], chatToSet = {}, event, eventType, status,
			} = action.payload;
			const { data: chatDataToSet, id: chatIdToSet } = chatToSet;
			const { status: toDeleteStatus, id: toDeleteId } = chatsToDelete[0] || {};

			const newEventTempId = event?.id || nanoid();

			const updatedChatHistory = state.chatHistory[state.chatHistory.length - 1]
				?.type === 'contact_typing'
				? [
					...state.chatHistory.slice(0, state.chatHistory.length - 1),
					{ id: newEventTempId, ...event },
					state.chatHistory[state.chatHistory.length - 1],
				]
				: [...state.chatHistory, { id: newEventTempId, ...event }];

			const newStateData: {
				inProgress?: boolean;
				inProgressSetEventToHistory?: boolean;
				chatHistoryCount?: number;
				closedCount?: number;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				[key: string]: any;
			} = {
				...state,
				inProgress: false,
				inProgressSetEventToHistory: false,
				chatHistory: event ? updatedChatHistory : state.chatHistory,
				chatHistoryCount: state.chatHistoryCount + 1,
			};

			if (event && eventType !== 'contact_typing') {
				newStateData.lastMessageId = newEventTempId;
			}

			if (state.chatInfo?.id === chatDataToSet?.id) {
				newStateData.chatInfo = chatDataToSet;
			}

			if (toDeleteId && toDeleteStatus) {
				chatsToDelete.forEach(({ id: toDeleteId, status: toDeleteStatus }: {
					id: string;
					status: string;
				}) => {
					// @ts-ignore
					newStateData[toDeleteStatus] = state[toDeleteStatus]
						.filter(({ id }: { id: number }) => id !== +toDeleteId);

					if (toDeleteStatus === 'closed') {
						newStateData.closedCount = state.closedCount - 1;
					}

					if (state.chatInfo?.id === +toDeleteId && !chatDataToSet) {
						newStateData.chatInfo = {};
					}
				});
			}

			if (chatDataToSet) {
				if (chatIdToSet) {
					const currentChatId = state.chatInfo?.id;
					if (
						currentChatId !== chatDataToSet.id
            && (eventType === 'contact_message' || eventType === 'callback_message')
            && chatDataToSet.status !== 'closed'
					) {
						// if the chat is new or opened and the event is a message,
						// then move the chat to the top of the list

						newStateData[status] = [
							chatDataToSet,
							// @ts-ignore
							...state[status].filter(({ id }) => id !== +chatIdToSet),
						];
					} else {
						// @ts-ignore
						newStateData[status] = state[status]
							// @ts-ignore
							.map((chat) => (chat.id === +chatIdToSet ? chatDataToSet : chat));
					}
				} else {
					newStateData[status] = toDeleteStatus === status
						? [chatDataToSet, ...newStateData[status]]
						// @ts-ignore
						: [chatDataToSet, ...state[status]];
				}

				if (!chatIdToSet && status === 'closed') {
					newStateData.closedCount = state.closedCount + 1;
				}
			}

			// @ts-ignore
			return newStateData;
		}
		case SET_NEW_CHAT_HISTORY_NOTIFICATIONS_INITIAL: {
			// filter out the closed chats
			const filteredNotifications = action.payload.withClosedChatsFilter
				? Object.entries(action.payload.dataFromLocalStorage)
					.reduce((acc, [chatId, messageIds]) => {
						if (
							state.new.some((chat) => chat.id === +chatId)
              || state.open.some((chat) => chat.id === +chatId)
						) {
							return { ...acc, [chatId]: messageIds };
						}
						return acc;
					}, {})
				: action.payload.dataFromLocalStorage;

			return {
				...state,
				newChatHistoryNotifications: filteredNotifications,
			};
		}
		case SET_NEW_CHAT_HISTORY_NOTIFICATION: {
			const { event } = action.payload;

			return {
				...state,
				newChatHistoryNotifications: {
					...state.newChatHistoryNotifications,
					[event?.chat_id]:
						[...(state.newChatHistoryNotifications?.[event?.chat_id] || []), event.id],
				},
			};
		}
		case RESET_NEW_CHAT_HISTORY_NOTIFICATIONS: {
			const { chatId } = action.payload;

			return {
				...state,
				newChatHistoryNotifications: { ...state.newChatHistoryNotifications, [chatId]: undefined },
			};
		}
		case SET_DATA_TYPE:
			return {
				...state,
				chatInfo: defaultState.chatInfo,
			};
		case SIGN_OUT:
			return defaultState;
		default:
			return state;
	}
};