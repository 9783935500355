import { put, takeLatest } from 'redux-saga/effects';

import { Location } from 'react-router-dom';
import { INITIALIZE_APP, SIGN_IN_SUCCESS } from '../constants';
import { getCurrentUser } from '../actions';
import { verifyToken } from './auth';
import { TNavigate } from '../../types';
import { globalHandleError } from '../../utils/globalHandleError';

function* authInit({
	navigate,
	location,
	changeLanguage,
}: {
	navigate: TNavigate,
	location: Location,
	changeLanguage: (lng: string) => void
}) {
	try {
		const token: string = yield verifyToken();
		if (token) {
			yield put({ type: SIGN_IN_SUCCESS, token });
			yield put(getCurrentUser(navigate, location, changeLanguage));
		} else {
			navigate('/sign-in');
		}
	} catch (error) {
		navigate('/sign-in');

		globalHandleError({
			module: 'init',
			subModule: 'authInit',
			error,
		});
	}
}

export default function* initSaga() {
	// @ts-ignore
	return [yield takeLatest(INITIALIZE_APP, authInit)];
}