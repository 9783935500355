import {
	takeLatest, takeEvery, call, put, all, select,
} from 'redux-saga/effects';

import {
	GET_CHAT_DETAILS,
	GET_CHAT_DETAILS_ERROR,
	GET_CHAT_DETAILS_SUCCESS,
	GET_CHATS_LIST,
	GET_CHATS_LIST_NOT_CLOSED,
	GET_CHATS_LIST_NOT_CLOSED_SUCCESS,
	GET_CHATS_LIST_ERROR,
	GET_CHATS_LIST_SUCCESS,
	SET_EVENT_TO_CHAT_HISTORY,
	SET_EVENT_TO_CHAT_HISTORY_SUCCESS,
	SEARCH_CHAT_MESSAGE,
	SET_FOUND_MESSAGES_IDS,
	GET_CHAT_DETAILS_ON_SCROLL,
	GET_CHAT_DETAILS_ON_SCROLL_SUCCESS,
	SET_EVENT_TO_CHAT_HISTORY_ERROR,
	FIND_ALL_MESSAGES,
	GET_ONLINE_CONTACTS_SUCCESS,
	SEARCH_CHAT_MESSAGE_SUCCESS,
	SEARCH_CHAT_MESSAGE_ERROR,
	SET_FOUND_MESSAGES_IDS_ERROR,
} from '../constants';
import { buildRequestGenerator, fetchData, showError } from '../../api';
import {
	selectChatsList,
	selectCurrentChatId,
	selectFirstMessageId,
	selectLastHistoryMessageId,
	selectLastMessageIdCurrent,
	selectUserMemberId, selectUserRole,
} from '../selectors';
import { doGetTableData } from './data';
import { SEARCH_SUBSTRING_CHAT_OPERATOR, SEARCH_VALUE_MIN_LENGTH } from '../../constants';
import { setContactOnlineStatus } from '../actions';
import { doGetFileUrl } from './fileUpload';
import { IApiSchema } from '../../types';
import { globalHandleError } from '../../utils/globalHandleError';
import { getIsAllowChatOperator } from '../../utils/getIsAllowChatOperator';

const CLOSED_CHATS_LIMIT = 15;
export const CHAT_DETAILS_LIMIT = 35;

const getChatsAdditionalQueryParams = (): { key: string; value: string; }[] => {
	const result: { key: string; value: string; }[] = [];

	const searchParams = new URLSearchParams(window.location.search);

	if (searchParams.get('chat_type')) {
		result.push({ key: 'operators', value: 'TRUE' });
	}

	if (searchParams.get('member_id')) {
		result.push({ key: 'operator_id', value: searchParams.get('member_id') as string });
	}

	if (searchParams.get('start-date')) {
		result.push({ key: 'from_time', value: searchParams.get('start-date') as string });
	}

	if (searchParams.get('end-date')) {
		result.push({ key: 'to_time', value: searchParams.get('end-date') as string });
	}

	return result;
};

function* doGetChatsList({
	payload: {
		queryParams = [], status, isInfiniteScroll, searchValue,
	},
}: {
	payload: {
		queryParams: { key: string; value: string | number; }[];
		status?: string;
		isInfiniteScroll?: boolean;
		searchValue?: string;
	}
}) {
	const role: IApiSchema['MemberRole'] = yield select(selectUserRole);
	const isAllowChatOperator = getIsAllowChatOperator(role);

	const isChatOperatorInSearch = window.location.search.includes(SEARCH_SUBSTRING_CHAT_OPERATOR);

	if (isAllowChatOperator && isChatOperatorInSearch) {
		queryParams.push(...getChatsAdditionalQueryParams());
	}

	if (status) {
		queryParams.push({ key: 'status', value: status });
	}

	if (status === 'closed' && !queryParams.some(({ key }) => key === 'limit')) {
		queryParams.push({ key: 'limit', value: CLOSED_CHATS_LIMIT });
	}

	if (searchValue && searchValue?.length >= SEARCH_VALUE_MIN_LENGTH) {
		queryParams.push({ key: 'searchValue', value: searchValue }, { key: 'searchFields', value: 'data' });
	}

	queryParams.push({
		key: 'sort',
		value: `${status === 'closed' ? 'status_changed_at' : 'last_message_at'}=desc`,
	});

	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'chats',
			queryParams,
		});
		const response: IApiSchema['LimitOffsetPage_ChatBrief_'] = yield fetchData(request);

		const closedCount = status === 'closed' ? response.total : '';

		yield showError(response);

		if (status) {
			yield put({
				type: GET_CHATS_LIST_SUCCESS,
				payload: {
					...response, status, closedCount, isInfiniteScroll,
				},
			});
		} else {
			return response;
		}
	} catch (error) {
		yield put({ type: GET_CHATS_LIST_ERROR });

		globalHandleError({
			module: 'chats',
			subModule: 'doGetChatsList',
			error,
		});
	}
}

function* doGetCurrentUserDepartments() {
	try {
		const userMemberId: number = yield select(selectUserMemberId);
		const items: IApiSchema['LimitOffsetPage_MemberBrief_']['items'] = yield doGetTableData({
			payload: {
				type: 'members',
				status: 'active',
				queryParams: [{ key: 'id', value: userMemberId }],
				noReduxSet: true,
			},
		});

		return items?.[0]?.departments;
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doGetCurrentUserDepartments',
			error,
		});

		return null;
	}
}

function transformForwardedChatInfo(chat: IApiSchema['ChatBrief'], currentUserId: number, currentUserDepartments: { id: number }[]) {
	const departments = currentUserDepartments
		?.map((department) => department.id).filter((dep) => !!dep);
	const isChatFromCurrentUser = +(chat.chat_data?.from_operator_id || 0) === +currentUserId;
	const isChatToCurrentUser = +(chat.chat_data?.to_operator_id || 0) === +currentUserId;
	const isChatToCurrentUserDepartments = departments
		&& departments.includes(+(chat.chat_data?.to_department_id || 0));

	if (isChatFromCurrentUser) {
		return {
			...chat,
			status: 'open',
			isChatFromCurrentUser,
			isForwarded: true,
		};
	}

	if (isChatToCurrentUser || isChatToCurrentUserDepartments) {
		return {
			...chat,
			status: 'new',
			isChatToCurrentUser,
			isChatToCurrentUserDepartments,
			isForwarded: true,
		};
	}

	return chat;
}

function* doGetChatById({ chatId }: { chatId: number }) {
	try {
		const newChatResponse: {
			items: IApiSchema['ChatBrief'][];
		} = yield doGetChatsList({
			payload: { queryParams: [{ key: 'id', value: chatId }] },
		});

		const currentUserDepartments: {
			id: number;
		}[] = yield doGetCurrentUserDepartments();

		const newChat = newChatResponse?.items?.[0];
		let chatResult;

		if (newChat?.status === 'forwarded') {
			const currentUserId: number = yield select(selectUserMemberId);

			chatResult = transformForwardedChatInfo(
				newChatResponse.items[0],
				currentUserId,
				currentUserDepartments,
			);
		} else {
			chatResult = newChat;
		}

		return chatResult;
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doGetChatById',
			error,
		});
	}
}

function* doGetOnlineContactsIds() {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'contactsOnline',
		});
		const response: unknown = yield fetchData(request);

		yield put({ type: GET_ONLINE_CONTACTS_SUCCESS, payload: response });
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doGetOnlineContactsIds',
			error,
		});
	}
}

function* doGetChatsListNotClosed({ searchValue }: { searchValue: string }) {
	const queryParams = [
		{ key: 'status__ne', value: 'closed' },
		{ key: 'sort', value: 'last_message_at=desc' },
	];

	const role: IApiSchema['MemberRole'] = yield select(selectUserRole);
	const isAllowChatOperator = getIsAllowChatOperator(role);

	const isChatOperatorInSearch = window.location.search.includes(SEARCH_SUBSTRING_CHAT_OPERATOR);

	if (isAllowChatOperator && isChatOperatorInSearch) {
		queryParams.concat(getChatsAdditionalQueryParams());
	}

	if (searchValue?.length >= SEARCH_VALUE_MIN_LENGTH) {
		queryParams.push({ key: 'searchValue', value: searchValue }, { key: 'searchFields', value: 'data' });
	}

	try {
		const currentUserId: number = yield select(selectUserMemberId);
		const currentUserDepartments: {
			id: number;
		}[] = yield doGetCurrentUserDepartments();

		const response: {
			items: IApiSchema['ChatBrief'][];
		} = yield doGetChatsList({ payload: { queryParams } });

		yield doGetOnlineContactsIds();

		const chatsByStatuses: {
			[key: string]: IApiSchema['ChatBrief'][];
		} = response.items.reduce((allChats, chat) => {
			const { status } = chat;

			const isForwardedChat = status === 'forwarded';

			// @ts-ignore
			const getChatsToSet = (status: string, chatToSet?: unknown) => (allChats[status]
				// @ts-ignore
				? [...allChats[status], chatToSet || chat]
				: [chatToSet || chat]);

			const allChatsToSet: {
				[key: string]: IApiSchema['ChatBrief'][];
			} = {
				...allChats,
				[status]: getChatsToSet(status),
			};

			if (isForwardedChat) {
				// @ts-ignore
				const transformedChat: {
					isChatFromCurrentUser?: boolean;
					isChatToCurrentUser?: boolean;
					isChatToCurrentUserDepartments?: boolean;
				} = transformForwardedChatInfo(
					chat,
					currentUserId,
					currentUserDepartments,
				);

				if (transformedChat.isChatFromCurrentUser) {
					allChatsToSet.open = getChatsToSet('open', transformedChat);
				}

				if (transformedChat.isChatToCurrentUser || transformedChat.isChatToCurrentUserDepartments) {
					allChatsToSet.new = getChatsToSet('new', transformedChat);
				}
			}

			return allChatsToSet;
		}, {});

		if (!chatsByStatuses.new) {
			chatsByStatuses.new = [];
		}

		if (!chatsByStatuses.open) {
			chatsByStatuses.open = [];
		}

		const payload = {
			...chatsByStatuses,
		};

		yield put({
			type: GET_CHATS_LIST_NOT_CLOSED_SUCCESS,
			payload,
		});
	} catch (error) {
		yield put({ type: GET_CHATS_LIST_ERROR });

		globalHandleError({
			module: 'chats',
			subModule: 'doGetChatsListNotClosed',
			error,
		});
	}
}

function* doMapEventWithFiles(
	event: { operator_pic: string; data: { files: { file_code: string }[] } },
	isHistoryFirstLoadWithPictures: null | { value: boolean },
) {
	try {
		let updatedEvent = { ...event };
		if (updatedEvent.operator_pic && !updatedEvent.operator_pic.startsWith('http')) {
			const operatorAvatar: {
				url: string;
			} = yield call(doGetFileUrl, { file_code: updatedEvent.operator_pic });
			updatedEvent = { ...updatedEvent, operator_pic: operatorAvatar.url };
		}
		if (updatedEvent.data?.files?.length) {
			const filesWithUrls: { file_code: string }[] = yield all(updatedEvent.data.files
				.map((file) => call(doGetFileUrl, file)));
			if (isHistoryFirstLoadWithPictures) {
				isHistoryFirstLoadWithPictures.value = true;
			}

			updatedEvent = { ...updatedEvent, data: { ...updatedEvent.data, files: filesWithUrls } };
		}
		return updatedEvent;
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doMapEventWithFiles',
			error,
		});
	}
}

function* doGetDataWithFileUrls(
	data: { operator_pic: string; data: { files: { file_code: string }[] } }[],
	isHistoryFirstLoadWithPictures?: null | { value: boolean },
) {
	try {
		const res: unknown = yield all(data.map((el) => call(
			// @ts-ignore
			doMapEventWithFiles,
			el,
			isHistoryFirstLoadWithPictures,
		)));

		return res;
	} catch (error) {
		globalHandleError({
			module: 'chats',
			subModule: 'doGetDataWithFileUrls',
			error,
		});
	}
}

function* doGetChatDetails(
	{ payload: { chatId } }: { payload: { chatId: number } },
) {
	// getting chat details on selecting new chat
	try {
		const queryParams: { key: string, value: string | number }[] = [
			{ key: 'chat_id', value: chatId },
			{ key: 'limit', value: CHAT_DETAILS_LIMIT },
		];

		queryParams.push({ key: 'sort', value: 'id=desc' });

		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'chatDetails',
			queryParams,
		});
		const response: IApiSchema['LimitOffsetPage_IChatEventBrief_'] = yield fetchData(request);

		yield showError(response);

		// get and set the current chat info when clicked on the chat
		const chatData: unknown = yield doGetChatById({ chatId });

		const isHistoryFirstLoadWithPictures = { value: false };

		const items: { id: number }[] = yield doGetDataWithFileUrls(
			// @ts-ignore
			response.items,
			isHistoryFirstLoadWithPictures,
		);

		yield put({
			type: GET_CHAT_DETAILS_SUCCESS,
			payload: {
				chatData,
				history: items.reverse(), // reverse for the very first history load
				total: response.total,
				allMessagesLoadedUp: response.total === items.length || CHAT_DETAILS_LIMIT > items.length,
				allMessagesLoadedDown: true,
				limit: CHAT_DETAILS_LIMIT,
				isHistoryFirstLoadWithPictures: isHistoryFirstLoadWithPictures.value,
				lastMessageId: items[items.length - 1]?.id,
			},
		});
	} catch (error) {
		yield put({ type: GET_CHAT_DETAILS_ERROR });

		globalHandleError({
			module: 'chats',
			subModule: 'doGetChatDetails',
			error,
		});
	}
}

function* doGetChatDetailsOnScroll({ payload: { isScrollUp, before = CHAT_DETAILS_LIMIT } }: {
	payload: { isScrollUp: boolean; before: number },
}) {
	try {
		const limit = isScrollUp ? before + 1 : before * 2 + 1;
		const thresholdMessageId: number = isScrollUp
			? yield select(selectFirstMessageId)
			: yield select(selectLastMessageIdCurrent);

		const queryParams = [
			{ key: 'limit', value: limit },
			{ key: 'before', value: before },
		];

		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'chatFoundNear',
			queryParams,
			id: thresholdMessageId,
		});
		const response: IApiSchema['LimitOffsetPage_IChatEventBrief_'] = yield fetchData(request);

		yield showError(response);

		const targetItemIndex = response.items.findIndex((el) => el.id === thresholdMessageId);
		const allMessagesLoadedInScrollDirection = isScrollUp
			? targetItemIndex !== before
			: response.items.length < limit;

		let finalResult;

		if (isScrollUp) {
			if (allMessagesLoadedInScrollDirection) {
				finalResult = response.items.slice(0, targetItemIndex); // remove messages
				// that are already loaded
			} else {
				finalResult = [...response.items];
				finalResult.pop(); // remove last item (it's the same as thresholdMessageId)
			}
		} else if (allMessagesLoadedInScrollDirection) {
			finalResult = response.items.slice(targetItemIndex + 1); // remove messages
			// that are already loaded
		} else {
			finalResult = response.items.slice(before + 1); // start from the next
			// item after the threshold
		}

		// @ts-ignore
		const items: unknown = yield doGetDataWithFileUrls(finalResult);
		yield put({
			type: GET_CHAT_DETAILS_ON_SCROLL_SUCCESS,
			payload: {
				history: items,
				isScrollUp,
				allMessagesLoadedInScrollDirection,
			},
		});
	} catch (error) {
		yield put({ type: GET_CHAT_DETAILS_ERROR });

		globalHandleError({
			module: 'chats',
			subModule: 'doGetChatDetailsOnScroll',
			error,
		});
	}
}

function* doFindAllMessages({ payload: { chatId, searchValue } }: {
	payload: { chatId: number; searchValue: string }
}) {
	const queryParams = [
		{ key: 'chat_id', value: chatId },
		{ key: 'limit', value: CHAT_DETAILS_LIMIT },
		{ key: 'sort', value: 'id=asc' },
	];

	queryParams.push({ key: 'searchValue', value: searchValue }, { key: 'searchFields', value: 'data' });

	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'chatDetails',
			queryParams,
		});
		const response: IApiSchema['LimitOffsetPage_IChatEventBrief_'] = yield fetchData(request);

		yield showError(response);

		yield put({
			type: SET_FOUND_MESSAGES_IDS,
			payload: response.items.map((el) => el.id),
		});
	} catch (error) {
		yield put({ type: SET_FOUND_MESSAGES_IDS_ERROR });

		globalHandleError({
			module: 'chats',
			subModule: 'doFindAllMessages',
			error,
		});
	}
}

function* doSearchChatMessage({ payload: { messageId } }: {
	payload: { messageId: number }
}) {
	try {
		const queryParams = [
			// { key: 'before', value: 50 },
			{ key: 'before', value: CHAT_DETAILS_LIMIT },
			{ key: 'sort', value: 'id=asc' },
			{ key: 'limit', value: CHAT_DETAILS_LIMIT * 2 + 1 },
			// { key: 'limit', value: 100 },
		];

		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'chatFoundNear',
			queryParams,
			id: messageId,
		});
		const response: IApiSchema['LimitOffsetPage_IChatEventBrief_'] = yield fetchData(request);

		yield showError(response);

		const targetItemIndex = response.items.findIndex((el) => el.id === messageId);
		const allMessagesLoadedUp = targetItemIndex < CHAT_DETAILS_LIMIT;
		const allMessagesLoadedDown = targetItemIndex > CHAT_DETAILS_LIMIT;

		// @ts-ignore
		const items: unknown = yield doGetDataWithFileUrls(response.items);

		yield put({
			type: SEARCH_CHAT_MESSAGE_SUCCESS,
			payload: {
				history: items,
				allMessagesLoadedUp,
				allMessagesLoadedDown,
			},
		});
	} catch (error) {
		yield put({
			type: SEARCH_CHAT_MESSAGE_ERROR,
		});

		globalHandleError({
			module: 'chats',
			subModule: 'doSearchChatMessage',
			error,
		});
	}
}

function* doSetEventToChatHistory({ payload: { data, type, isReceivedEvent } }: {
	payload: {
		data: {
			chat_id: number;
			contact_id: number;
			operator_id: number;
			status: string;
			type: string;
		};
		type: string;
		isReceivedEvent: boolean
	}
}) {
	try {
		const currentChatId: number = yield select(selectCurrentChatId);
		const lastMessageIdFromServer: number = yield select(selectLastHistoryMessageId);
		const lastMessageIdReduxState: number = yield select(selectLastMessageIdCurrent);

		let newChatData: {
			isChatFromCurrentUser?: boolean;
			isChatToCurrentUser?: boolean;
			isChatToCurrentUserDepartments?: boolean;
			id?: number;
			status?: string;
		} | undefined;
		if (data.chat_id) {
			newChatData = yield doGetChatById({ chatId: data.chat_id });
		}

		const payload: {
			status?: string;
			chatToSet?: { data: unknown; id?: number | string };
			chatsToDelete?: { status: string; id: number }[];
			event?: unknown;
			eventType?: string;
		} = {};

		if (type === 'chat_started') {
			payload.status = 'new';
			payload.chatToSet = { data: newChatData };
			payload.chatsToDelete = [{ status: 'open', id: data.chat_id }]; // why from opened?
		} else if (type === 'chat_closed' && newChatData) {
			// delete from opened, add to closed top
			payload.status = 'closed';
			payload.chatToSet = { data: { ...newChatData, status: 'closed' } };
			payload.chatsToDelete = [{ status: 'open', id: data.chat_id }];

			if (isReceivedEvent) {
				payload.chatsToDelete = [{ status: 'open', id: data.chat_id }];
			}
		} else if (type === 'operator_joined') {
			if (!isReceivedEvent) {
				return;
			}
			const currentOperatorId: number = yield select(selectUserMemberId);
			const isCurrentOperatorAcceptedChat = data?.operator_id === currentOperatorId;

			if (isCurrentOperatorAcceptedChat) {
				// if accept button is clicked by current operator,
				// add new chat to opened chats and delete chat from new chats
				payload.status = 'open';
				payload.chatToSet = { data: newChatData };
				payload.chatsToDelete = [{ status: 'new', id: data.chat_id }];
			}

			// if another operator joined chat, delete chat from open chats if chat was forwarded
			if (!isCurrentOperatorAcceptedChat) {
				payload.chatsToDelete = [
					{ status: 'open', id: data.chat_id },
					{ status: 'new', id: data.chat_id },
				];
			}
		} else if (type === 'forwarded_operator' || type === 'forwarded_department') {
			if (newChatData?.isChatFromCurrentUser) {
				payload.status = 'open';
				payload.chatToSet = { data: newChatData }; // add transformed chat data
				// to top of opened chats
				payload.chatsToDelete = [{ status: 'open', id: newChatData.id || 0 }]; // delete old chat data
				// from opened chats
			} else if (newChatData?.isChatToCurrentUserDepartments || newChatData?.isChatToCurrentUser) {
				// if forward event is received
				payload.status = 'new';
				payload.chatToSet = { data: newChatData }; // add transformed chat data to top of new chats
			} else {
				// if chat that was previously forwarded to you was forwarded to another operator
				payload.chatsToDelete = [{ status: 'new', id: data.chat_id }];
			}
		} else if (['contact_message', 'operator_message', 'system_message', 'callback_message'].includes(type)) {
			if (!newChatData) {
				return;
			}
			// replace chat data in new or opened chats
			payload.status = newChatData.status || '';
			payload.chatToSet = { data: newChatData, id: newChatData.id };

			if (type === 'contact_message') {
				// set online status for this guest
				yield put(setContactOnlineStatus(true, data.contact_id));
			}

			if ((data.status === 'new' || newChatData.status === 'new')
				&& isReceivedEvent && type === 'callback_message') {
				const closedChats: {
					id: number;
				}[] = yield select(selectChatsList('closed'));
				if (closedChats.some((el) => el.id === data.chat_id)) {
					payload.chatsToDelete = [{ status: 'closed', id: data.chat_id }];
					payload.chatToSet.id = '';
				}
			}
		}

		// only chats list last message is updated (when search is active), not chat details
		if (lastMessageIdFromServer === lastMessageIdReduxState && +data.chat_id === +currentChatId) {
			// @ts-ignore
			const dataWithFileUrls: unknown[] = yield call(doGetDataWithFileUrls, [data]);

			// eslint-disable-next-line prefer-destructuring
			payload.event = dataWithFileUrls[0];
		} else if (data.type === 'contact_typing') {
			payload.event = data;
		}

		payload.eventType = data.type;

		yield put({
			type: SET_EVENT_TO_CHAT_HISTORY_SUCCESS,
			payload,
		});
	} catch (error) {
		yield put({ type: SET_EVENT_TO_CHAT_HISTORY_ERROR });

		globalHandleError({
			module: 'chats',
			subModule: 'doSetEventToChatHistory',
			error,
		});
	}
}

export default function* sagas() {
	return [
		// @ts-ignore
		yield takeLatest(GET_CHATS_LIST, doGetChatsList),
		// @ts-ignore
		yield takeLatest(GET_CHATS_LIST_NOT_CLOSED, doGetChatsListNotClosed),
		// @ts-ignore
		yield takeEvery(SET_EVENT_TO_CHAT_HISTORY, doSetEventToChatHistory),
		// @ts-ignore
		yield takeLatest(GET_CHAT_DETAILS, doGetChatDetails),
		// @ts-ignore
		yield takeLatest(GET_CHAT_DETAILS_ON_SCROLL, doGetChatDetailsOnScroll),
		// @ts-ignore
		yield takeLatest(FIND_ALL_MESSAGES, doFindAllMessages),
		// @ts-ignore
		yield takeLatest(SEARCH_CHAT_MESSAGE, doSearchChatMessage),
	];
}