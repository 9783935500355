// import * as Sentry from '@sentry/react';

interface IGlobalHandleErrorParams {
	module: string;
	subModule: string;
	error: unknown;
}

export const globalHandleError = (params: IGlobalHandleErrorParams) => {
	const {
		module,
		subModule,
		error,
	} = params;

	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.error(
			'ERROR \n',
			`Module: ${module} \n`,
			`SubModule: ${subModule} \n`,
			error,
		);
	} else {
		// TODO: uncomment after fix errors
		// Sentry.captureException(error);
	}
};